import React from "react";
import { Popover, Menu, Row } from "antd";
import "./style.css";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import GridGenerator from "../../lib/megaMenuHelper";


const { SubMenu } = Menu

const GetMenu = ({ his, data, parentData }) => {
    return (
        <div className="menu-popover">
            <Row  >
                <GridGenerator data={data} his={his} parentData={parentData} cols={2}>

                </GridGenerator>
            </Row>
        </div>
    )
}

const GetForPc = ({ his, data }) => {
    const [width, setWidth] = useState(0);
    const [left, setLeft] = useState(40);

    function rafAsync() {
        return new Promise(resolve => {
            requestAnimationFrame(resolve); //faster than set time out
        });
    }

    async function checkElement(selector) {
        let querySelector = null;
        while (querySelector === null) {
            await rafAsync();
            querySelector = document.querySelectorAll(selector);
        }
        return querySelector;
    }
    useEffect(() => {
        if (width === 0) {
            let tempWidth = 0
            checkElement('.menu-li') //use whichever selector you want
                .then((element) => {
                    for (let i = 0; i < element.length; i++) {
                        var positionInfo = element[i].getBoundingClientRect();
                        // console.log(positionInfo.width);
                        tempWidth += positionInfo.width;
                    }
                    setWidth(tempWidth);
                    //Do whatever you want now the element is there
                });
        }
    }, [width, data])

    return (
        <div className="cs-not-for-mobile d jc-c" style={{ backgroundColor: '#394747' }}>
            <div style={{ maxWidth: '1442px', width: '100%' }}  >
                <div className="mega-menu-wrapper">
                    <ul className="menu-ul" id="menu-ul">
                        {data && <li className="menu-li-clone arc-sub-title" onClick={() => his.push('/all-categories')} >{"All Categories"}</li>}
                        {data.map(parentData =>
                            <Popover overlayClassName={window.innerWidth >= 1920 ? "abs-pop-hd" : "abs-pop"}
                                overlayStyle={{ width: width + 115, right: '100px !important' }}
                                content={<GetMenu his={his} parentData={parentData} data={parentData.children} />}
                                placement="bottomLeft">
                                <li className="menu-li arc-sub-title" key={parentData.id} >{parentData.name}</li>
                            </Popover>
                        )}
                    </ul>
                </div>
            </div>
        </div>
    )
}

const GetFormobile = ({ his, data }) => {

    const handleClick = ({ key }) => {
        let [id, name] = key.split('___')
        his.push(`/category/${id}/${name.split(' ').join('-')}`)
        setOpenKeys([])
    };

    const [openKeys, setOpenKeys] = useState([])

    return (
        <Menu
            className="cs-only-for-mobile "
            onClick={handleClick}
            // style={{ backgroundColor: "#5b7070" }}
            defaultSelectedKeys={['sub1']}
            theme="dark"
            mode="inline"
            openKeys={openKeys}
            onOpenChange={(key) => setOpenKeys(key)}

        >
            <SubMenu style={{ backgroundColor: '#3d4b4b', width: '100%' }} key="sub1" title="Categories">
                {
                    data.map(parentData =>
                        <SubMenu style={{ backgroundColor: '#3d4b4b', width: '100%' }} key={parentData.id} title={parentData.name}>
                            {
                                parentData.children.map(masterData =>
                                    <SubMenu style={{ backgroundColor: '#4a5c5c', swidth: '100%' }} key={masterData.id} title={masterData.name}>
                                        {masterData.children.map(subMasterData =>
                                            <Menu.Item style={{ backgroundColor: '#546969', margin: 0 }} key={subMasterData.id + "___" + subMasterData.name}>{subMasterData.name}</Menu.Item>
                                        )}
                                    </SubMenu>
                                )
                            }
                        </SubMenu>
                    )
                }
            </SubMenu>
        </Menu>
    )
}


export const MegaMenu = ({ history }) => {
    const [data, setData] = useState([])

    const allData = useSelector(state => state.allDataReducer)

    useEffect(() => {
        if (allData.productMapping) {
            setData(allData.productMapping)
        }
    }, [allData.productMapping])

    return (
        <>
            <GetForPc his={history} data={data} />
            <GetFormobile his={history} data={data} />
        </>
    );
};
