import React from "react";
import { Provider, useDispatch } from "react-redux";
import { BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import { store } from "./redux/index";
import PublicRoute from "./custom-routes/PublicRoute";
import PrivateRoute from "./custom-routes/PrivateRoute";
import { history } from "./config/history";
import { Login } from "./pages/login";
import { SignUp } from "./pages/SignUp";
import { ToastContainer } from 'react-toastify';
import { HomePage } from "./pages/home";
import { ProductPage } from "./pages/product";
import { SingleProduct } from "./pages/singleProduct";
import { usePromiseTracker } from "react-promise-tracker";
import { Spin } from "antd";
// import "antd/dist/antd.css";
// import 'bootstrap/dist/css/bootstrap.min.css';
import './globalStyle/antd.css'
import './globalStyle/style.css';
import "./globalStyle/globalStyle.css";
import "./static/style/main.css";
import 'react-inner-image-zoom/lib/InnerImageZoom/styles.css';
import { BlogPage } from "./pages/blog";

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import { FavouritPage } from "./pages/favourite";
import { QuotePage } from "./pages/quote";
import 'react-toastify/dist/ReactToastify.css';
import { ToTheTrade } from "./pages/extra/toTheTrade";
import { ExtraPage } from "./pages/extra";
import { Account } from "./pages/myAccount";
import { SearchPage } from "./pages/serach";
import { ForgetPassword } from "./pages/forgotpassword";
import ScrollToTop from './lib/scroll'
import { OrderPage } from "./pages/order";
import { MemberPage } from "./pages/extra/member";
import { mangeLogin } from "./redux/actions/appStatus";
import { Blog1 } from "./pages/blog/blog1";
import { CategoriesPage } from "./pages/categories";
import { AllCategoriesPage } from "./pages/allCategories";
import { AllProductPage } from "./pages/allProduct";

const LoadingIndicator = props => {
	const { promiseInProgress } = usePromiseTracker();

	return promiseInProgress &&
		<div style={{
			top: 0,
			zIndex: 9996666699,
			// position: "absolute",
			// width: "100vw",
			// height: "100vh",
			// background: "#FFFFFF88"
		}}>
			<span style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%,-50%)' }} ><Spin size={'large'} /></span>
		</div>;
}

const App = () => {


	return (
		<div>
			<Provider store={store}>
				<Router history={history} >
					<ScrollToTop />
					<Switch>
						<PublicRoute exact path="/" component={HomePage} />
						<PublicRoute exact path="/category/:id/:name" component={ProductPage} />
						<PublicRoute exact path="/categories/:id/:name" component={CategoriesPage} />
						<PublicRoute exact path="/all-categories/" component={AllCategoriesPage} />
						<PublicRoute exact path="/all-product/" component={AllProductPage} />
						<PublicRoute exact path="/log-in" component={Login} />
						<PublicRoute exact path="/password-reset" component={ForgetPassword} />
						<PublicRoute exact path="/sign-up" component={SignUp} />
						<PublicRoute exact path="/blog" component={BlogPage} />
						<PublicRoute exact path="/to-the-trade" component={ToTheTrade} />
						<PublicRoute exact path="/pages/:id" component={ExtraPage} />
						<PublicRoute exact path="/product/:id/:parentID/:name" component={SingleProduct} />
						<PublicRoute exact path="/search/:name/:name" component={SearchPage} />
						<PublicRoute exact path="/member" component={MemberPage} />
						<PublicRoute exact path="/blog/:name" component={Blog1} />
						<PrivateRoute exact path="/favourite" component={FavouritPage} />
						<PrivateRoute exact path="/quote" component={QuotePage} />
						<PrivateRoute exact path="/order" component={OrderPage} />
						<PrivateRoute exact path="/account" component={Account} />
						<Redirect from="/home" to="/" />
						<Redirect from="/products" to="/all-product/" />
					</Switch>
				</Router>
			</Provider>
			<ToastContainer autoClose={3200} />
			<LoadingIndicator />
		</div>
	);
};

export default App;
