import React, { useState } from 'react';
import { Row, Col, Form, Input, Button, Space } from 'antd';
import { sendMailByUser } from '../../elements/api/other';
import { responseHelper } from '../../lib/response';
import Reaptcha from 'reaptcha';

export const ContactUs = ({ data, history }) => {
    const [disable, setDisable] = useState(true)
    const [success, setSuccess] = useState(false)
    const onSubmit = async (value) => {
        let message = "";
        for (let key of Object.keys(value)) {
            message += `${key} : ${value[key]} \n`
        }
        let resp = await sendMailByUser({ message })
        responseHelper(resp, () => setSuccess(true))
    }

    return (
        <div>
            <h1 className="fnt-50 fnt-56 m-0">
                Contact Us
            </h1>
            <p>
                We are here to answer any questions you may have about our services and products.
                Send us your question and we’ll respond as soon as we can.
            </p>
            <Row gutter={[16, 16]} align="middle" >
                <Col sm={24} xs={24} md={12} xl={12}>
                    <div className="">
                        <div className="mt-20">
                            {success ? <div>
                                <h3>Successfully sent your query.</h3>
                                <Button className="mt-10" type="primary" onClick={() => history.push('/')} >Back to home</Button>
                            </div> : <Form id="" onFinish={onSubmit} >
                                <div className="app-form-full">
                                    <div className="app-form-label">Name *</div>
                                    <Form.Item name="name" rules={[{ required: true, message: "Name is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className="app-form-full">
                                    <div className="app-form-label">Email *</div>
                                    <Form.Item name="email" rules={[{ required: true, message: "Email is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className="app-form-full">
                                    <div className="app-form-label">Phone Number *</div>
                                    <Form.Item name="phoneNumber" rules={[{ required: true, message: "Phone number is required" }]}>
                                        <Input />
                                    </Form.Item>
                                </div>
                                <div className="app-form-full">
                                    <div className="app-form-label">Message *</div>
                                    <Form.Item name="message" rules={[{ required: true, message: "Message is required" }]}>
                                        <Input.TextArea />
                                    </Form.Item>
                                </div>
                                <Space align="start" direction="vertical" >
                                    <Reaptcha sitekey="6Leo37YbAAAAABJYR-072waIh0N_-s22W4SyPSoG" onExpire={() => setDisable(true)}
                                        onError={() => setDisable(true)} size="" onVerify={() => setDisable(false)} />
                                    <div class="app-form-full">
                                        <button htmltype="submit" disabled={disable} id="login-form" class="app-cta-btn primary">
                                            Submit
                                        </button>
                                    </div>
                                </Space>
                            </Form>}
                        </div>
                    </div>
                </Col>
                <Col sm={24} xs={24} md={12} xl={12}>
                    <div className="display-column jc-sb p-2" style={{ alignItems: "center", textAlign: 'center' }} >
                        <div>
                            <div className="fnt-40 m-0">
                                Give a Call
                            </div>
                            <p>
                                For Sale:
                                +91 7665529259
                            </p>
                            <p>
                                For Support:
                                +91 7665529259
                            </p>
                        </div>
                        <div className="mt-20">
                            <div className="fnt-40 m-0">
                                Mail & Chat
                            </div>
                            <p>
                                For Sale:
                                fb@furnitureboutiq.com
                            </p>
                            <p>
                                For Support:
                                support@furnitureboutiq.com
                            </p>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
