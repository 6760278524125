import React from 'react'
import { AppController } from '../../appController/AppController'
import { MainWrapper } from '../../lib/wrapper'
import { ProductCard } from '../../components/cards/productCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setProducAction } from '../../redux/actions/allData'
import { useLocation } from 'react-router-dom'
import { Button } from 'antd'
import './style.css'
import { uniqBy } from 'lodash'

export const SearchPage = ({ history }) => {
    const dispatch = useDispatch()
    const allData = useSelector(state => state.allDataReducer)
    const [data, setData] = useState(null)
    const [search, setsearch] = useState(false)
    const [all, setAll] = useState(false)
    let location = useLocation();

    useEffect(() => {
        setsearch(!search)
        let text = location.pathname.split('/')[3]
        let subMasterID = location.pathname.split('/')[2]
        if (allData.product.length) {
            let res = []
            if (subMasterID === 'a_l_l_l') {
                let test = []
                let ids = JSON.parse(text)
                ids.map(arr => arr.map(d => test.push(d)))
                res = allData.product.filter(data => test.includes(data.parentID));
                // console.log(ids);
                setAll(true)
            } else {
                if (text === "a_l_l") {
                    res = allData.product || []
                    setAll(true)
                } else {
                    res = allData.product.filter(data => data.name.toLowerCase().includes(text.toLowerCase()) || data.sku.toLowerCase().includes(text.toLowerCase())) || []
                    if (subMasterID !== "0") {
                        res = res.filter(data => data.parentID === subMasterID);
                    }
                    setAll(false)
                }
            }
            setData(uniqBy(res, "id"))
        } else dispatch(setProducAction())

    }, [allData.product, location])
    
    window.onscroll = function () { scrollFunction() };

    function scrollFunction() {
        var mybutton = document.getElementById("myBtn");

        if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
            if (mybutton)
                mybutton.style.display = "block";
        } else {
            if (mybutton)
                mybutton.style.display = "none";
        }
    }

    return (
        <div>
            <AppController callback={search} history={history}>
                <div style={{ position: 'relative' }}>
                    <div id="top" />
                    <MainWrapper>
                        <div style={{ textAlign: 'center' }}>
                            {!all && <h1 className="fnt-50 lh-58">{"Search Result" || "Loading..."}</h1>}
                        </div>
                        <div className="mt-30 mb-30 grid">
                            {data !== null ? data.map(productData =>
                                <ProductCard history={history} data={productData} noAction={false} />

                            ) : <p>Sorry no result found, for your query.</p>}
                        </div>
                    </MainWrapper>
                    <div style={{ position: 'fixed', right: '25px', bottom: '103px', zIndex: 999999999 }} >
                        <Button style={{ display: 'none' }} id="myBtn" onClick={() => document.getElementById("top").scrollIntoView({
                            behavior: 'smooth'
                        })} >Top</Button>
                    </div>
                </div>
            </AppController>
        </div>
    )
}
