import React from 'react'
import { Row, Col } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import dummyImage from '../../assets/images/dummy.jpg'
import { imageURL } from '../../lib/constant'

export const ChoseUsPage = () => {
    return (
        <div className="mobile-mt">
            <Row gutter={[16, 16]} align="middle" >
                <Col xs={24} sm={24} md={12}>
                    <div className="mobile-mt display-column p-2">
                        <h1 className="cs-sub-title">The easiest way to deliver commerical project</h1>
                        <div className="display-column pl-30">
                            <div className="d ai-fs">
                                <ArrowRightOutlined className="mt-5" />
                                <div className="display-column ml-30">
                                    <h2 className="cs-content-title">HOTELS</h2>
                                    <p className="p-small">With high-quality hotel guest room furnishings, you can ensure that your visitors are
                                        comfortable and at ease. Choose Furniture BoutiQ if you're

                                        seeking a one-stop shop for hotel furnishings for sale.
                                        {/* <br /> */}
                                        {/* <br />
                                        We offer customizable solutions for all
                                        furniture and decorative needs of hotel room and suite , restaurant

                                        and lobby , cafe and restaurant projects. With our design line add magnificance to all your
                                        spaces.
                                        <br /> */}
                                        {/* <br /> */}
                                        Our artisans and designers work with great care and attention to detail to produce

                                        flawless, well-executed, and tasteful furnished projects to meet your hotel furnishing demands
                                        precisely as you want them.</p>
                                </div>
                            </div>
                            <div className="d ai-fs mt-20 mb-20">
                                <ArrowRightOutlined className="mt-5" />
                                <div className="display-column ml-30">
                                    <h2 className="cs-content-title">RESTAURANT</h2>
                                    <p className="p-small">A restaurant's ambiance sets the tone.
                                        With our experienced designers , transform your restaurant into an artwork.
                                        We pay attention to each intricate detail , ingenuity and time required when
                                        styling a space , making your dream restaurant furniture a reality!
                                        <br />
                                        Our restaurant furniture line is moulded on authenticity and craftmanship , never forsaking durability. </p>
                                </div>
                            </div>
                            <div className="d ai-fs mb-20">
                                <ArrowRightOutlined className="mt-5" />
                                <div className="display-column ml-30">
                                    <h2 className="cs-content-title">ARCHITECTURE</h2>
                                    <p className="p-small" > Discover the finest products , proficiency and architectural inspiration at Furniture BoutiQ.
                                        <br />
                                        We deliver the finest quality architecture designs the industry has to offer for both homes  and  enterprises  striving  for quality  and seeking  to  meet  your furniture design  needs from  start  to  finish.</p>
                                </div>
                            </div>
                            <div className="d ai-fs">
                                <ArrowRightOutlined className="mt-5" />
                                <div className="display-column ml-30">
                                    <h2 className="cs-content-title">INTERIOR</h2>
                                    <p className="p-small"> Buying furniture that perfectly complements your interior design is now at your disposal! Elevate  the  concept  of decorating  your home  and  interior spaces with  FurnitureBoutiq.
                                        <br />
                                        Give  your indoor and  outdoor  areas  a  personal  touch  whilst  adhering  to  the dynamics and  spirit  of décor.</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </Col>
                <Col className="cs-not-for-mobile mobile-mt" xs={24} sm={24} md={12}>
                    <div className="display-column">
                        <div className="d jc-fs">
                            <div className="choose-us-image-wrapper">
                                <img src={`${imageURL}Restraurent Furniture.jpeg`} alt="" />
                                <div className="p-1">
                                    <p className="pre-title mb-10">For Restaurants</p>
                                </div>
                            </div>
                            <div className="choose-us-image-wrapper">
                                <img src={`${imageURL}Interior Designer.jpeg`} alt="" />
                                <div className="p-1">
                                    <p className="pre-title mb-10">For Interior</p>
                                </div>
                            </div>
                        </div>
                        <div className="d jc-fe" >
                            <div className="choose-us-image-wrapper">
                                <img src={`${imageURL}Hotel Furniture.jpg`} alt="" />
                                <div className="p-1">
                                    <p className="pre-title mb-10">For Hotel</p>
                                </div>
                            </div>
                            <div className="choose-us-image-wrapper">
                                <img src={`${imageURL}Architecture .jpeg`} alt="" />
                                <div className="p-1">
                                    <p className="pre-title mb-10">For Architect</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
