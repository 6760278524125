import { chunk } from 'lodash'
import * as React from 'react'
import { Col } from 'antd'
const GridGenerator = ({ cols, children, data, his, parentData }) => {
    const rows = chunk(React.Children.toArray(children), cols)
    const length = rows.length - 1;
    let max = 0;
    data.map(masterData => {
        if (max <= parseInt(masterData.sectionOrder)) max = parseInt(masterData.sectionOrder)
    })
    return (
        <>
            {["1", "2", "3", "4"].map((cols, colsIdx) => (
                <>
                    <Col key={colsIdx} span={4}>
                        {
                            cols === "1" && <h2 className="mb-10 arc-title all-prod"
                                onClick={() => his.push(`/categories/${parentData.id}/All-${parentData.name.split(' ').join('-')}`)}
                                key={parentData.key} >{`All ${parentData.name}`}</h2>
                        }
                        {data.map(
                            masterData => masterData.sectionOrder === cols && <>
                                <div>
                                    <h2 className="mb-10 arc-title" key={masterData.key} >{masterData.name}</h2>
                                    <ul className="menu-item-ul">
                                        {
                                            masterData.children.map(subMasterData => <>
                                                <li onClick={() => his.push(`/category/${subMasterData.id}/${subMasterData.name.split(' ').join('-')}`)} className="menu-item-li" key={subMasterData.id} >{subMasterData.name}</li>
                                            </>)
                                        }
                                    </ul>
                                </div></>
                        )}
                    </Col>
                    {(colsIdx <= max - 1 && colsIdx !== 3) && <div className="d">
                        <div className="vr-line"></div>
                    </div>}
                </>
            ))}
        </>
    )
}
export default GridGenerator