import React, { useEffect, useState } from 'react'
import { Skeleton, Tooltip } from 'antd'
import { FolderOutlined, CloseOutlined } from '@ant-design/icons'
import './style.css'
import { AddToFav } from '../addToFav'
import { RequestQuote } from '../requestQuote'
import { uploadFileUrl } from '../../lib/constant'
import { setFavouriteItem } from '../../elements/api/other'
import im from './placeholder-image.png'

export const ProductCard = ({ noAction, noTitle, removeAction, data, history, size, favId, call }) => {

    const [thumbNail, setThumbNail] = useState("")

    const [load, setLoad] = useState(false);
    useEffect(() => {
        if (data) {
            let thumbNail = "";
            let images = data.images.split(',');
            if (images[0]) setThumbNail(images[0]);
        }
    }, [data])

    const handleDelete = async (id) => {
        let res = await setFavouriteItem({ action: "DELETE", id })
        if (res.status === "1") {
            call()
            // window.location.reload()
        }
    }

    const viewCardDetails = () => {
        // const win = window.open(`https://furnitureboutiq.com/product/${data.id}`, "_blank");
        // win.focus();
        history.push(`/product/${data.id}/${data.parentID}/${data.name.split(' ').join('-')}`)
    }

    return !data ? null :
        <div className="product-card">
            <div className={"card-image-wrapper " + (!noTitle && "mb-20")}  >
                {/* image */}
                {!load && <div className="card-image cs-not-for-mobile cur " onClick={viewCardDetails}>
                    <img style={{ width: '100%' }} src={im} />
                </div>}
                <div className={"card-image cs-not-for-mobile cur "} onClick={viewCardDetails}>
                    <img onLoad={() => setLoad(true)} style={{ width: '100%' }} src={uploadFileUrl + thumbNail} alt={data.name} />
                </div>
                {!load && <div className="card-image cs-only-for-mobile cur " onClick={viewCardDetails}>
                    <img style={{ width: '100%' }} src={im} />
                </div>}
                <div className="card-image cs-only-for-mobile cur " onClick={viewCardDetails}>
                    <img onLoad={() => setLoad(true)} style={{ width: '100%' }} src={uploadFileUrl + thumbNail} alt={data.name} />
                </div>
                {favId && <div className="card-action mt-10">
                    <Tooltip title="Remove this from favourite">
                        <CloseOutlined onClick={() => handleDelete(favId)} className="icon-wrapper ml-10" />
                    </Tooltip>
                </div>}
            </div>
            {!noTitle && <h2 className="m-0 product-title cur" onClick={viewCardDetails}>{data.name}</h2>}
        </div>

}


//  {/* actions */}
//  {noAction ? null : <><div className="card-action mt-10">
//  <AddToFav history={history} productData={data} />
//  <Tooltip title="View Details">
//      <FolderOutlined onClick={viewCardDetails} className="icon-wrapper ml-10 cur" />
//  </Tooltip>
//  {/* {removeAction && 
//  <Tooltip title="Remove this from favourite">
//      <CloseOutlined className="icon-wrapper ml-10" />
//  </Tooltip>} */}
// </div>
//  <div className="card-action on-hover">
//      <RequestQuote productData={data} />
//  </div></>}