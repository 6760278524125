import React, { useEffect } from 'react'
import { Row, Col, Button, Steps, Carousel } from 'antd';
import { ArrowLeftOutlined, ArrowRightOutlined, FileOutlined, RightOutlined } from '@ant-design/icons';
import DummyImage from '../../assets/images/dummy.jpg'
import { UserOutlined, SolutionOutlined, LoadingOutlined, SmileOutlined } from '@ant-design/icons';
import './style.css'
import { imageURL, uploadFileUrl } from '../../lib/constant';
import { useState } from 'react';
import { getSlider } from '../../elements/api/other';

import Reaptcha from 'reaptcha';

const { Step } = Steps;

export const Main = ({ history }) => {
    const [data, setData] = useState(null)
    const [upside, setUpside] = useState(null)
    const [lowerSide, setLowerSide] = useState(null)

    useEffect(() => {

        // loadReCaptcha("6LdaPvkZAAAAABlnVf0rYCgTKTzsx9sF_v9cM9vY", () => { });
        init()
    }, [])

    const init = async () => {
        let res = await getSlider();
        if (res.status !== "0") {
            let formValue = res.data[0]
            formValue.images = formValue.images.split(',')
            if (formValue.name1 && formValue.images[0]) {
                setUpside({ text: formValue.name1, img: formValue.images[0] })
            }
            if (formValue.name2 && formValue.images[1]) {
                setLowerSide({ text: formValue.name2, img: formValue.images[1] })
            }
            if (formValue.images.length > 2) {
                let all = [];
                for (let index = 2; index < formValue.images.length - 1; index++) {
                    all.push(formValue.images[index]);
                }
                // console.log(all);
                setData(all)
            }
            // toast.success(res.msg);
        }
        // toast.error(res.msg);
    }
    const verifyCallback = (recaptchaToken) => {
        // Here you will get the final recaptchaToken!!!  
        // console.log(recaptchaToken, "<= your recaptcha token")
    }
    return (
        <section >
            <Row gutter={[16, 16]} justify="space-around"  >
                <Col sm={24} xs={24} md={12}>
                    <Row gutter={24} align="top" >
                        <Col span={24}>
                            <div className="display-column mt-30"   >
                                <div className="display-column ml-30 ai-fs" >
                                    <div  >
                                        <h2 className=" cs-pl m-0" style={{ lineHeight: '40px', fontSize: 32 }} >Welcome to <br />
                                        </h2>
                                        <h2 className="fnt-50 lh-56 cs-pl" style={{ lineHeight: '56px' }} >
                                            <span className="cs-pl  mr-20">FURNITURE </span>
                                            <br />
                                            <span className="cs-pl ml-30" style={{ marginLeft: 90 }} >BOUTIQ </span>
                                        </h2>
                                    </div>
                                </div>
                                <p className="p-small mb-20 " style={{ padding: '0em 1.5em 0em 0em' }}>
                                    We have been manufacturing handcrafted furniture. We specialize in solid wood,
                                    industrial, and leather furniture.
                                    Our furniture business, based in Jodhpur, has "its origins in contemporary India but sells items all
                                    over the world.
                                    <br />
                                    We engage with both B2B and B2C customers and are open to 'made-to-order' customization
                                    process for all our customers.
                                    <br />
                                    We specialize in 'client-specific' furniture designs that are created the old-fashioned manner, using
                                    high-quality materials
                                    and skilled craftmanship , giving your home a one-of-a-kind look. We create your dream furniture
                                    and turn your ideas into reality.
                                    <br />
                                    Convey your own ideas for your furniture or browse through our diverse furniture designs and
                                    choose the one that best suits your needs.
                                    At Furniture Boutiq you can order even a single piece of furniture or order in bulk. We collaborate
                                    with a number of interior designers and architects for their projects.
                                </p>
                                <Button type="primary" onClick={() => history.push('/all-product')} className="mt-20">Browse Product <RightOutlined /></Button>
                            </div>
                        </Col>
                        <Col span={24} className="cs-not-for-mobile">
                            <div className="progress_wrapper mobile-mt mb-20">
                                <div className="progress_inner_box">
                                    <div className="upper_section cs-line">
                                        <h5 className="box_name">Enquire</h5>
                                        <div className="box_icon_wrapper">
                                            <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/source-d5ced2250a5d810ff0a277d29bed02e4.svg" />
                                        </div>
                                    </div>
                                    <div className="lower_section">
                                        <p className="box_text">Share all your project requirements</p>
                                    </div>
                                </div>
                                <div className="progress_inner_box">
                                    <div className="upper_section cs-line">
                                        <h5 className="box_name">Get Proposal</h5>
                                        <div className="box_icon_wrapper">
                                            <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/compare-8e543347b49bef558790475d7f7608c6.svg" />
                                        </div>
                                    </div>
                                    <div className="lower_section">
                                        <p className="box_text">Receive &amp; evaluate multiple quotes</p>
                                    </div>
                                </div>
                                <div className="progress_inner_box">
                                    <div className="upper_section cs-line">
                                        <h5 className="box_name">Order</h5>
                                        <div className="box_icon_wrapper">
                                            <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/cart-bbf9ac3a36e2ae228fa5f09d7af5d35e.svg" />
                                        </div>
                                    </div>
                                    <div className="lower_section">
                                        <p className="box_text">Pay using multiple secure options</p>
                                    </div>
                                </div>
                                <div className="progress_inner_box no-effrect">
                                    <div className="upper_section">
                                        <h5 className="box_name">Receive</h5>
                                        <div className="box_icon_wrapper">
                                            <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/delivery-e352826062ede24e696d4d0874b0b491.svg" />
                                        </div>
                                    </div>
                                    <div className="lower_section">
                                        <p className="box_text">Manage project deadlines &amp; deliveries</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col sm={24} xs={24} md={11}>
                    <div className="display-column ai-center">
                        <Carousel autoplaySpeed={2000} style={{ marginTop: window.innerWidth <= 1110 ? 10 : 60 }}
                            arrows nextArrow={<ArrowRightOutlined className="icon-wrapper" />}
                            prevArrow={<ArrowLeftOutlined />} dotPosition={window.innerWidth <= 1110 ? "top" : "bottom"} effect="fade" autoplay >
                            {data !== null && data.map(imge =>
                                <img style={{ maxHeight: 500, width: '80%' }} src={uploadFileUrl + imge} alt="sfd" />)
                            }
                        </Carousel>
                        <div className="first-wrapper p-1">
                            <div className="d jc-c">
                                {upside && <img style={{ borderRadius: 4, width: 150 }} src={uploadFileUrl + upside.img} alt="" />
                                }                                        </div>
                        </div>
                        <div className="second-wrapper p-1">
                            <div className="d jc-c">
                                {lowerSide && <img style={{ borderRadius: 4, width: 150 }} src={uploadFileUrl + lowerSide.img} alt="" />}
                            </div>

                        </div>
                    </div>
                </Col>
                <Col sm={24} xs={24} className="d jc-c cs-only-for-mobile">
                    <div className="progress_wrapper mobile-mt mb-20">
                        <div className="progress_inner_box mt-20">
                            <div className="upper_section cs-line">
                                <h5 className="box_name">Enquire</h5>
                                <div className="box_icon_wrapper">
                                    <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/source-d5ced2250a5d810ff0a277d29bed02e4.svg" />
                                </div>
                            </div>
                            <div className="lower_section">
                                <p className="box_text">Share all your project requirements</p>
                            </div>
                        </div>
                        <div className="progress_inner_box mt-20">
                            <div className="upper_section cs-line">
                                <h5 className="box_name">Get Proposal</h5>
                                <div className="box_icon_wrapper">
                                    <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/compare-8e543347b49bef558790475d7f7608c6.svg" />
                                </div>
                            </div>
                            <div className="lower_section">
                                <p className="box_text">Receive &amp; evaluate multiple quotes</p>
                            </div>
                        </div>
                        <div className="progress_inner_box mt-20">
                            <div className="upper_section cs-line">
                                <h5 className="box_name">Order</h5>
                                <div className="box_icon_wrapper">
                                    <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/cart-bbf9ac3a36e2ae228fa5f09d7af5d35e.svg" />
                                </div>
                            </div>
                            <div className="lower_section">
                                <p className="box_text">Pay using multiple secure options</p>
                            </div>
                        </div>
                        <div className="progress_inner_box mt-20 no-effrect">
                            <div className="upper_section">
                                <h5 className="box_name">Receive</h5>
                                <div className="box_icon_wrapper">
                                    <img alt="" className="box_icon" src="https://www.treniq.com/assets/treniq/homepage_revamp_assets/delivery-e352826062ede24e696d4d0874b0b491.svg" />
                                </div>
                            </div>
                            <div className="lower_section">
                                <p className="box_text">Manage project deadlines &amp; deliveries</p>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
        </section>
    )
}
