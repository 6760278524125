import { toast } from "react-toastify";
import { encodeParams, modifyFetch } from "../../lib/apiHelper";

let URL = "https://furnitureboutiq.com/api/products";


export const getParent = async () => {
    const response = await modifyFetch(`${URL}/parent.php`);
    const body = await response.json()
    if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
    return body;
}

export const getMaster = async () => {
    const response = await modifyFetch(`${URL}/master.php`);
    const body = await response.json()
    if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
    return body;
}

export const getSubMaster = async () => {
    const response = await modifyFetch(`${URL}/subMaster.php`);
    const body = await response.json()
    if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
    return body;
}

export const getMapping = async () => {
    const parent = await getParent();
    const master = await getMaster();
    const subMaster = await getSubMaster();

    let res = [];

    const getFilterMaster = (id) => master.data.filter(data => data.parentID === id) || [];
    const getFilterSubMaster = (id) => subMaster.data.filter(data => data.parentID === id) || [];
    if (parent.status !== "0" && master.status !== "0" && subMaster.status !== "0") {
        // console.log(parent)
        let parentSubMasterMap = {}
        res = parent.data.map(parentData => {
            return {
                ...parentData,
                children: getFilterMaster(parentData.id).map(masterData => {
                    return {
                        ...masterData,
                        children: getFilterSubMaster(masterData.id).map(subMasterData => {
                            Object.assign(parentSubMasterMap, { [subMasterData.id]: parentData.id })
                            return subMasterData;
                        })
                    }
                })
            }
        })

        // console.log(res);
        return ({ status: "1", data: res, parent, master, subMaster, parentSubMasterMap })
    } else {
        return { status: "0", data: [] }
    }

}

export const getProduct = async () => {
    const response = await modifyFetch(`${URL}/product.php`);
    let body = await response.json()
    if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
    if(body.status === "1"){
        body.data = body.data.sort(function(valueA, valueB) {
            let a = typeof valueA.name === 'string' ? valueA.name.toLowerCase() : valueA.name.toString();
            let b = typeof valueB.name === 'string' ? valueB.name.toLowerCase() : valueB.name.toString();
            a = valueA.name.trim() 
            b = valueB.name.trim() 
            return a.localeCompare(b);
        });
    }
    let newProductArr = []
    for(let product of body.data){
        let parentID = product.parentID.split(',')
        for(let id of parentID){
            newProductArr.push({
                ...product,
                parentID:`${id}`
            })
        }
    }
    // console.log(newProductArr)
    return {data:newProductArr, status:"1"};
}

export const getProductByID = async (data) => {
    const response = await modifyFetch(`${URL}/product.php?${encodeParams(data)}`);
    const body = await response.json()
    if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
    return body;
}

