import * as actionTypes from './actionTypes'
import { getMapping, getMaster, getParent, getProduct, getSubMaster } from "../../elements/api/product";
import { getOrder, getQuote, getUser } from '../../elements/api/other';

export const setParentAction = () => {
	return async (dispatch, getState) => {
		let res = await getParent();
		let data = []
		if (res.status === "1") {
			data = res.data
		}
		dispatch({
			type: actionTypes.setParent,
			payload: data
		});
	};
};

export const setMasterAction = () => {
	return async (dispatch, getState) => {
		let res = await getMaster();
		let data = []
		if (res.status === "1") {
			data = res.data
		}
		dispatch({
			type: actionTypes.setMaster,
			payload: data
		});
	};
};


export const setSubMasteAction = () => {
	return async (dispatch, getState) => {
		let res = await getSubMaster();
		let data = []
		if (res.status === "1") {
			data = res.data
		}
		dispatch({
			type: actionTypes.setSubMaster,
			payload: data
		});
	};
};


export const setProducAction = () => {
	return async (dispatch, getState) => {
		let res = await getProduct();
		let data = []
		if (res.status === "1") {
			data = res.data.filter(data => data.draft === "0")
		}
		console.log(data)
		dispatch({
			type: actionTypes.setProduct,
			payload: data
		});
	};
};


export const setUserAction = () => {
	return async (dispatch, getState) => {
		let res = await getUser();
		let data = []
		if (res.status === "1") {
			data = res.data
		}
		dispatch({
			type: actionTypes.setUser,
			payload: data
		});
	};
};

export const setOrderAction = () => {
	return async (dispatch, getState) => {
		let res = await getOrder();
		let data = []
		if (res.status === "1") {
			data = res.data
		}
		dispatch({
			type: actionTypes.setOrder,
			payload: data
		});
	};
};


export const setQuoteAction = () => {
	return async (dispatch, getState) => {
		let res = await getQuote();
		let data = []
		if (res.status === "1") {
			data = res.data
		}
		dispatch({
			type: actionTypes.setQuote,
			payload: data
		});
	};
};

export const setLoadMore = (data) => {
	return async (dispatch, getState) => {
		dispatch({
			type: actionTypes.setLoadMore,
			payload: data
		});
	};
};


export const setProductMapping = () => {
	return async (dispatch, getState) => {
		let res = await getMapping();
		let data = []
		if (res.status === "1") {
			data = res.data
		}
		dispatch({
			type: actionTypes.setParent,
			payload: res.parent.data
		});
		dispatch({
			type: actionTypes.setMaster,
			payload: res.master.data
		});
		dispatch({
			type: actionTypes.setSubMaster,
			payload: res.subMaster.data
		});
		dispatch({
			type: actionTypes.setParentSubMasterMapping,
			payload: res.parentSubMasterMap
		});
		dispatch({
			type: actionTypes.setMapping,
			payload: data
		});
	};
};


