import React from 'react'

export const Privacy = ({data}) => {
    return (
        <div>
            <h1>Privacy Policy</h1>
            {data && <div className="mt-20" dangerouslySetInnerHTML={{__html:data}} />}
        
        </div>
    )
}
