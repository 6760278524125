import React from "react";
import { Form, Input, Row, Col, Button } from "antd";
import { useEffect } from "react";
import { setAddress } from "../../elements/api/other";
import { useSelector } from "react-redux";

const { Item } = Form;

export const AddressForm = ({ type, data, callback }) => {
    const [form] = Form.useForm();
    const appStatus = useSelector(state => state.appStatus)

    useEffect(() => {
        if (data) {
            let formValue = {}
            if (type === "Billing" && data.payment) {
                data.payment = data.payment.replace(/\\n/g, "\\n")
                    .replace(/\\'/g, "\\'")
                    .replace(/\\"/g, '\\"')
                    .replace(/\\&/g, "\\&")
                    .replace(/\\r/g, "\\r")
                    .replace(/\\t/g, "\\t")
                    .replace(/\\b/g, "\\b")
                    .replace(/\\f/g, "\\f");

                data.payment = data.payment.replace(/[\u0000-\u0019]+/g, "");
                formValue = JSON.parse(data.payment);
            } else if(data.shipping ) {
                data.shipping = data.shipping.replace(/\\n/g, "\\n")
                    .replace(/\\'/g, "\\'")
                    .replace(/\\"/g, '\\"')
                    .replace(/\\&/g, "\\&")
                    .replace(/\\r/g, "\\r")
                    .replace(/\\t/g, "\\t")
                    .replace(/\\b/g, "\\b")
                    .replace(/\\f/g, "\\f");

                data.shipping = data.shipping.replace(/[\u0000-\u0019]+/g, "");
                formValue = JSON.parse(data.shipping);

            }
            form.setFieldsValue({ ...formValue })
        }
    }, []);

    const onSubmit = async value => {
        let action = "INSERT";
        if(data)
            action = "UPDATE"
        if (type === "Billing") {
            let payment = JSON.stringify(value)
            let shipping = "";
            if (data && data.shipping) shipping = data.shipping
            await setAddress({ parentID: appStatus.id, shipping, payment, action })
            callback()
        } else {
            let shipping = JSON.stringify(value)
            let payment = "";
            if (data && data.payment) payment = data.payment
            await setAddress({ parentID: appStatus.id, shipping, payment, action})
            callback()
        }
    };

    return (
        <div>
            <Form form={form} onFinish={onSubmit}>
                <Row gutter={[16, 16]} className="mb-30">
                    <Col xs={24} sm={24} md={12} xl={12}>
                        <Item name="firstName" label="First Name">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={12} xl={12}>
                        <Item name="lastName" label="Last Name">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="company" label="Company">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="country" label="Country">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="street" label="Street">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="town" label="Town / City">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="state" label="State">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="pincode" label="PinCode">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="phone" label="Phone">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Col xs={24} sm={24} md={24} xl={24}>
                        <Item name="email" label="Email">
                            <Input style={{ width: "50%" }} />
                        </Item>
                    </Col>
                    <Button type="primary" htmlType="submit">
                        Save
                    </Button>
                </Row>
            </Form>
        </div>
    );
};
