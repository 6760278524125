export const uploadFileUrl = "https://furnitureboutiq.com/api/upload/"
export const imageURL = "https://furnitureboutiq.com/stagging/images/"
export const userImageURL = "https://furnitureboutiq.com/stagging/images/user/"
export const aboutImageURL = "https://furnitureboutiq.com/stagging/images/about/"
export const tradeImageURL = "https://furnitureboutiq.com/stagging/images/tarde/"
export const blogImageURL = "https://furnitureboutiq.com/stagging/blogImages/"


// https://sg-files.hostinger.in/handler.php?action=download?action=download&path=%2Fdomains%2Ffurnitureboutiq.com%2Fpublic_html%2FblogImages%2F1%2Fandrea-davis-ytCOr2yDuBg-unsplash-min.jpg


export const pre = ``