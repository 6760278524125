/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react'
import { Tooltip, Modal, Row, Col, Divider, Select, Input, Button } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import { getModalTitle } from '../../lib/extra';
import { ProductCard } from '../cards/productCard';
import { getFavouriteListById, setFavouriteItem, setFavouriteList } from '../../elements/api/other';
import { useDispatch, useSelector } from 'react-redux';
import { responseHelper } from '../../lib/response';
import { FileOutlined, HeartOutlined, MoreOutlined } from '@ant-design/icons'

const { Option } = Select;

export const AddToFav = ({ productData, history }) => {

	const [visible, setVisible] = useState(false);
	const [selectedListItem, setSelectedListItem] = useState(null)
	const [success, setSuccess] = useState(false)

	const appStatus = useSelector(state => state.appStatus)
	const dispatch = useDispatch()
	const checkStatus = () => {
		if (!appStatus.login) {
			history.push('/log-in')
		}
	}

	const CustomSelect = () => {
		const [items, setItems] = useState([])
		const [name, setName] = useState('')

		useEffect(() => {
			init()
		}, [])

		const init = async () => {
			let res = await getFavouriteListById({ id: appStatus.id })
			responseHelper(res, () => setItems([...res.data]))
		}

		const onNameChange = event => {
			setName(event.target.value)
		};

		const addItem = async () => {
			if (name !== "") {
				let res = await setFavouriteList({ name, action: "INSERT", parentID: appStatus.id })
				responseHelper(res, () => init())
				setName('')
			}
		};

		return (
			<Select
				defaultValue={selectedListItem}
				onChange={(val) => setSelectedListItem(val)}
				style={{ width: '100%' }}
				placeholder="select ..."
				dropdownRender={menu => (
					<div>
						{menu}
						<Divider style={{ margin: '4px 0' }} />
						<div style={{ display: 'flex', flexWrap: 'nowrap', padding: 8 }}>
							<Input style={{ flex: 'auto' }} value={name} onChange={onNameChange} />
							<a style={{ flex: 'none', padding: '8px', display: 'block', cursor: 'pointer' }}
								onClick={addItem}
							>
								<PlusOutlined /> Add item
							</a>
						</div>
					</div>
				)}
			>
				{items.map(item => <Option value={item.id} key={item.id}>{item.name}</Option>)}
			</Select>

		)
	}

	const onsubmit = async () => {
		let res = await setFavouriteItem({
			action: "INSERT",
			email: appStatus.username,
			parentID: selectedListItem, productID: productData.id, sku: productData.sku
		})
		responseHelper(res, () => { setSuccess(true); })
	}

	const getModalContent = () => {
		return (
			<>
				<Row gutter={[16, 16]}>
					<Col sm={24} xs={24} md={24}>
						<p>Add this product to your collection of favourities</p>
					</Col>
					<Col sm={12} xs={12} md={12}>
						<ProductCard data={productData} noAction={true} />
					</Col>
					{!success ? <Col sm={12} xs={12} md={12}>
						<h3>Please select name for your collection</h3>
						<CustomSelect />
						<Button className="mt-10" type="primary" onClick={onsubmit} >Add</Button>
					</Col> :
						<Col sm={12} xs={12} md={12}>
							<h3>Successfully added your product to desired list</h3>
							<Button className="mt-10" type="primary" onClick={() => history.push('/favourite')} >View Favourite</Button>
						</Col>}
				</Row>
			</>
		)
	}

	const onCancel = () => {
		setVisible(false)
		setSelectedListItem(null)
		setSuccess(false)
	}

	return (
		<div>
			<Modal
				closable={false}
				visible={visible}
				onCancel={onCancel}
				footer={null}
			>
				{getModalTitle("Add To Favourites", onCancel)}
				<div className="p-1">
					{getModalContent()}
				</div>
			</Modal>
			<Button style={{ width: '100%', margin: '20px 10px 20px 10px' }}
				onClick={() => { setVisible(true); checkStatus() }}
				icon={<HeartOutlined />} type="dashed">Add to Favorite</Button>


		</div>
	)
}
