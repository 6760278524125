import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { mangeLogin } from '../redux/actions/appStatus';

function ScrollToTop({ history }) {
  const dispatch = useDispatch();

  useEffect(() => {
    // document.addEventListener('contextmenu', event => event.preventDefault());
    if (localStorage.getItem('d59517ae-e16e-11eb-ba80-0242ac130004') == "true" &&
      localStorage.getItem('id') !== null && localStorage.getItem('username') !== null) {
      dispatch(mangeLogin(true, localStorage.getItem('id'), localStorage.getItem('username')))
    }
    const unlisten = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      unlisten();
    }
  }, []);

  return (null);
}

export default withRouter(ScrollToTop);