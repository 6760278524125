import React, { useState } from "react";
import { Layout, Menu, Drawer, } from "antd";
import { menuLayoutA } from "../../elements/layout/AppControllerLayout";
import "./sideMenu.css";
import { useDispatch, useSelector } from "react-redux";
import { mangeLogin } from "../../redux/actions/appStatus";

const { SubMenu } = Menu;

export const SideMenu = props => {

	let { collapsed, setcollapsed, history } = props;
	const [menuLayout, setmenuLayout] = useState(menuLayoutA);
	const appStatus = useSelector(state => state.appStatus)

	const dispatch = useDispatch()

	const getMenuItem = (menuItem) => {
		if (appStatus.login) {
			return ((menuItem.view !== "notLogin") &&
				<Menu.Item
					style={{ backgroundColor: '#e4fcee', margin: 0 }}
					className="sidebar-menu-bg"
					theme="dark"
					key={menuItem.key}
					icon={menuItem.icon}
					onClick={() => {
						if (menuItem.path === 'logout') {
							localStorage.setItem("d59517ae-e16e-11eb-ba80-0242ac130004", false);
							localStorage.setItem("id", null);
							localStorage.setItem("username", null);
							dispatch(mangeLogin(false, null, null))
						} else {
							history.push(menuItem.path)
						}
						setcollapsed(!collapsed);
					}}
				>
					{menuItem.title}
				</Menu.Item>
			)
		} else {

			return ((menuItem.view !== "login") &&
				<Menu.Item
					style={{ backgroundColor: '#e4fcee', margin: 0 }}
					className="sidebar-menu-bg"
					theme="dark"
					key={menuItem.key}
					icon={menuItem.icon}
					onClick={() => {
						if (menuItem.path === 'logout') {
							localStorage.setItem("d59517ae-e16e-11eb-ba80-0242ac130004", null);
							localStorage.setItem("id", null);
							localStorage.setItem("username", null);
							dispatch(mangeLogin(false, null, null))
						} else {
							history.push(menuItem.path)
						}
						setcollapsed(!collapsed);
					}}
				>
					{menuItem.title}
				</Menu.Item>
			)
		}
	}
	return (
		<Drawer
			width={280}
			closable={false}
			visible={collapsed}
			drawerStyle={{ backgroundColor: '#e4fcee' }}
			onClose={() => setcollapsed(false)}
			getContainer={false}
			style={{ position: 'absolute', height:'100vh' }}  
			placement="left"
		>
			{/* <p>MAIN MENU</p> */}
			<Menu  mode="inline">
				{menuLayout.map(menuItem =>
					menuItem.type !== "parent" ? getMenuItem(menuItem) :
						<SubMenu  style={{backgroundColor: '#e4fcee'}} key={menuItem.key} icon={menuItem.icon} title={menuItem.title} >
							{menuItem.children.map(menuItem => getMenuItem(menuItem))}
						</SubMenu>
				)}
			</Menu>
		</Drawer>
	);
};
