import React, { useState } from 'react'
import { Row, Col, Menu } from 'antd'
import { AppController } from '../../appController/AppController'
import { MainWrapper } from '../../lib/wrapper'
import { HeartOutlined } from '@ant-design/icons'
import { ProductCard } from '../../components/cards/productCard'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { responseHelper } from '../../lib/response'
import { getFavouriteItemByID, getFavouriteListById } from '../../elements/api/other'
import { setProducAction } from '../../redux/actions/allData'

const { SubMenu } = Menu;

export const FavouritPage = ({ history }) => {
    const [list, setList] = useState(null)
    const [item, setItem] = useState(null)
    const [productLoaded, setProductLoaded] = useState(false);
    const [slectedKey, setSlectedKey] = useState(null);
    const appStatus = useSelector(state => state.appStatus)

    const allData = useSelector(state => state.allDataReducer);
    const dispatch = useDispatch();

    useEffect(() => {
        init()
        if (allData.product.length) {
            setProductLoaded(true);
        } else dispatch(setProducAction())
    }, [allData.product])

    useEffect(() => {
        if (list) {
            getItems()

        }
    }, [list])

    const getItems = async () => {
        let allPromise = []
        let items = {}
        for (let i = 0; i < list.length; i++) {
            allPromise.push(getFavouriteItemByID({ id: list[i].id }))
        }
        let res = await Promise.all(allPromise);
        for (let i = 0; i < list.length; i++) {
            Object.assign(items, { [list[i].id]: res[i].data })
        }
        setItem(items)
    }

    const init = async () => {
        let res = await getFavouriteListById({ id: appStatus.id })
        responseHelper(res, () => setList([...res.data]))
    }

    const handleClick = ({ key }) => {
        setSlectedKey(key);
    };

    const getProduct = (id) => allData.product.filter(data => data.id === id)[0];

    const getMenu = () => {
        return (
            <Menu
                onClick={handleClick}
                // style={{ width: 256 }}
                defaultSelectedKeys={['1']}
                theme="light"
                mode="inline"
                defaultOpenKeys={['sub1']}
            >
                <SubMenu key="sub1" icon={<HeartOutlined />} title="My Favourites">
                    {list && list.map(data => <Menu.Item key={data.id} >{data.name}</Menu.Item>)}
                </SubMenu>
            </Menu>
        )
    }

    return (
        <div>
            <AppController history={history}>
                <MainWrapper>
                    <Row gutter={[16, 16]} >
                        <Col sm={24} md={24} xs={24}>
                            <h1 className="fnt-sz-32 m-0">Favourite</h1>
                            <p>Here you can see all your saved collections.</p>
                        </Col>
                        <Col sm={24} xs={24} md={6} lg={6}>
                            {getMenu()}
                        </Col>
                        <Col sm={24} xs={24} md={18} lg={18}>
                            {productLoaded && <div className="grid">
                                {item && item[slectedKey] && item[slectedKey].map(data =>
                                    <ProductCard favId={data.id} data={getProduct(data.productID)}
                                        call={() => init()}
                                        history={history} removeAction={true} />
                                )}
                            </div>}
                        </Col>
                    </Row>
                </MainWrapper>
            </AppController>
        </div>
    )
}
