import React, { useState } from 'react'
import { useEffect } from 'react'
import { blogImageURL } from '../../lib/constant'
import { useLocation } from 'react-router-dom'
import { blog1 } from './mainContent'
import './artilce.css'
import { AppController } from '../../appController/AppController'
import back from './back.png';

export const Blog1 = ({ history }) => {


    const [title, setTitle] = useState("")
    const [blogData, setBlogData] = useState([])

    let location = useLocation();

    useEffect(() => {
        const blogName = location.pathname.substring(6).split('-').join(' ')
        // console.log(blogName);
        if (blog1[blogName]) {
            setTitle(blogName)
            setBlogData(blog1[blogName])
        }
    }, [location])

    return (
        <div>
            <AppController history={history}>
                <article class="app-article">
                    <div class="app-article-header-image">
                        <img alt="Header, Entryways the feel like summer.jpg" height="321"
                            src={back}
                            width="1083" />
                    </div>
                    <div class="app-article-header app-article-centered">
                        <h1 class="playfair-38-0-R app-article-title">
                            {title || ""}
                        </h1>
                    </div>

                    {blogData.map((data, idx) => <div id={idx} key={idx}>
                        <h2 class="app-article-heading playfair-27-0-R app-article-centered">
                            {data.title}
                        </h2>
                        <div class="app-article-richtext app-article-richtext-links app-article-centered">
                            <div class="rich-text">
                                <p dangerouslySetInnerHTML={{ __html: data.content }} />
                            </div>
                        </div>
                        {data.images.map((images, id) => id === 0 && <div class="app-article-block-image">
                            <img alt="Los Feliz Spanish Colonial by Commune Design, Living Room View.jpg"
                                height="471" src={blogImageURL + images}
                                width="660" />
                            {/* <div class="app-article-image-caption-centered app-article-double-image-caption acre-10-1-R">
                                {images.split('/')[1]}
                            </div> */}
                        </div>)}
                        {/* {data.images.length === 3 && <div class="app-article-block-image">
                            <img alt="Los Feliz Spanish Colonial by Commune Design, Living Room View.jpg"
                                height="471" src={blogImageURL + data.images[2]}
                                width="660" />
                            <div class="app-article-image-caption-centered app-article-double-image-caption acre-10-1-R">
                                {data.images[2].split('/')[1]}
                            </div>
                        </div>} */}
                        {/* <div class="app-article-block-double-image">
                            <div class="app-article-block-double-left">
                                <img alt={data.images[0]}
                                    height="434"
                                    src={blogImageURL + data.images[0]}
                                    width="310" />
                                <div class="app-article-image-caption-centered app-article-double-image-caption acre-10-1-R">
                                    {data.images[0].split('/')[1]}
                                </div>
                            </div>
                            <div class="app-article-block-double-right">
                                <img alt={data.images[1]}
                                    height="434"
                                    src={blogImageURL + data.images[1]}
                                    width="310" />
                                <div class="app-article-image-caption-centered app-article-double-image-caption acre-10-1-R">
                                    {data.images[1].split('/')[1]}
                                </div>
                            </div>
                        </div> */}
                    </div>)}
                </article>
            </AppController>
        </div>
    )
}
