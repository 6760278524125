import React, { useEffect, useState } from "react";
import {
	Modal,
	Row,
	Col,
	Input,
	Button,
	Form,
	InputNumber,
	Space
} from "antd";
import { getModalTitle } from "../../lib/extra";
import { ProductCard } from "../cards/productCard";
import { useDispatch, useSelector } from "react-redux";
import { sendMailByUser, setQuote } from "../../elements/api/other";
import { responseHelper } from "../../lib/response";
import { setQuoteAction } from "../../redux/actions/allData";
import Reaptcha from "reaptcha";
import moment from "moment";

export const RequestQuote = ({ productData, his, selectedColour, selectedSize }) => {
	const [visible, setVisible] = useState(false);
	const [enquiry, setEnquiry] = useState(false);
	const [disable, setDisable] = useState(true);
	const [buttonKEY, setKey] = useState(0);

	const [size, setSelectedSize] = useState(selectedSize)
	const [colour, setsSlectedColour] = useState(selectedColour)


	useEffect(()=>{
		setSelectedSize(selectedSize)
	},[selectedSize])

	useEffect(()=>{
		setsSlectedColour(selectedColour)
	},[selectedColour])

	const [form] = Form.useForm()

	const appStatus = useSelector(state => state.appStatus)

	const dispatch = useDispatch()

	const sendMail = async (value) => {
		if (appStatus.username !== undefined)
			value.email = appStatus.username;
		value.productID = productData.id;
		const userMail = value.email;
		const productName = productData.name;
		const productSKU = productData.sku
		const quantity = value.quantity;
		const image = productData.images.split(',')[0];
		const imageUrl = "https://furnitureboutiq.com/api/upload/" + image;
		const date = moment().format("DD MMMM YYYY")
		const productUrl = "https://furnitureboutiq.com/product/" + productData.id + "/" + productData.name;
		const zipcode = value.zipcode;
		const country = value.country;
		const message = value.message;
		const mailContent = `<div id="m_-1368018750309305127m_-32694759113509277wrapper" dir="ltr"
		style="background-color:#f7f7f7;margin:0;padding:70px 0;width:100%">
		<table border="0" cellpadding="0" cellspacing="0" height="100%" width="100%">
			<tbody>
				<tr>
					<td align="center" valign="top">
						<div id="m_-1368018750309305127m_-32694759113509277template_header_image">
						</div>
						<table border="0" cellpadding="0" cellspacing="0" width="600"
							id="m_-1368018750309305127m_-32694759113509277template_container"
							style="background-color:#ffffff;border:1px solid #dedede;border-radius:3px">
							<tbody>
								<tr>
									<td align="center" valign="top">

										<table border="0" cellpadding="0" cellspacing="0" width="100%"
											id="m_-1368018750309305127m_-32694759113509277template_header"
											style="background-color:#2a3636;color:#ffffff;border-bottom:0;font-weight:bold;line-height:100%;vertical-align:middle;font-family:Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;border-radius:3px 3px 0 0">
											<tbody>
												<tr>
													<td id="m_-1368018750309305127m_-32694759113509277header_wrapper"
														style="padding:36px 48px;display:block">
														<h1
															style="font-family:Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;font-size:30px;font-weight:300;line-height:150%;margin:0;text-align:left;color:#ffffff;background-color:inherit">
															Request a quote</h1>
													</td>
												</tr>
											</tbody>
										</table>

									</td>
								</tr>
								<tr>
									<td align="center" valign="top">

										<table border="0" cellpadding="0" cellspacing="0" width="600"
											id="m_-1368018750309305127m_-32694759113509277template_body">
											<tbody>
												<tr>
													<td valign="top" id="m_-1368018750309305127m_-32694759113509277body_content"
														style="background-color:#ffffff">

														<table border="0" cellpadding="20" cellspacing="0" width="100%">
															<tbody>
																<tr>
																	<td valign="top" style="padding:48px 48px 32px">
																		<div id="m_-1368018750309305127m_-32694759113509277body_content_inner"
																			style="color:#636363;font-family:Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;font-size:14px;line-height:150%;text-align:left">

													

																			<p style="margin:0 0 16px">You have received a request for a quote. The request is the following:

																			:</p>

																			<p style="margin:0 0 16px"><strong>Request
																					date</strong>: ${date}</p>

																			<table cellspacing="0" cellpadding="6"
																				style="width:100%;border:1px solid #eee;border-collapse:collapse">
																				<thead>
																					<tr>
																						<th scope="col" style="padding:12px;text-align:left;border:1px solid #eee">
																							Preview</th>
																						<th scope="col" style="padding:12px;text-align:left;border:1px solid #eee">
																							Product</th>
																						<th scope="col" style="padding:12px;text-align:left;border:1px solid #eee">
																							Quantity</th>
																						${colour ? `<th scope="col" style="padding:12px;text-align:left;border:1px solid #eee">
																							Colour</th>` : ''}
																						${size ? `<th scope="col" style="padding:12px;text-align:left;border:1px solid #eee">
																							Colour</th>` : ''}
																					</tr>
																				</thead>
																				<tbody>
																					<tr>
																						<td scope="col"
																							style="color:#636363;vertical-align:middle;padding:12px;text-align:center;border:1px solid #eee">
																							<a href="${productUrl}"
																								style="color:#2a3636;font-weight:normal;text-decoration:underline"
																								target="_blank"
																								data-saferedirecturl="https://www.google.com/url?q=${productUrl}&amp;source=gmail&amp;ust=1627901032373000&amp;usg=AFQjCNF-uW5Xf1DfPSQAUQPsKXmqrVJnvA"><img
																									src="${imageUrl}"
																									height="100" width="100"
																									style="border:none;display:inline-block;font-size:14px;font-weight:bold;height:auto;outline:none;text-decoration:none;text-transform:capitalize;vertical-align:middle;margin-right:10px;max-width:100%"
																									class="CToWUd"></a>
																						</td>

																						<td scope="col"
																							style="color:#636363;vertical-align:middle;padding:12px;text-align:left;border:1px solid #eee">
																							<a href="${productUrl}"
																								style="color:#2a3636;font-weight:normal;text-decoration:underline"
																								target="_blank"
																								data-saferedirecturl="https://www.google.com/url?q=${productUrl}&amp;source=gmail&amp;ust=1627901032373000&amp;usg=AFQjCNF-uW5Xf1DfPSQAUQPsKXmqrVJnvA">${productName}
																								
																								SKU:${productSKU}</a>

																							<small>
																							</small>
																						</td>
																						<td scope="col"
																							style="color:#636363;vertical-align:middle;padding:12px;text-align:center;border:1px solid #eee">
																							${quantity}</td>
																				${colour ?			`<td scope="col"
																							style="color:#636363;vertical-align:middle;padding:12px;text-align:center;border:1px solid #eee">
																							${colour}</td>` : ''}
																							${size ?			`<td scope="col"
																							style="color:#636363;vertical-align:middle;padding:12px;text-align:center;border:1px solid #eee">
																							${size}</td>` : ''}
																					</tr>
																			
																				
																				</tbody>
																			</table>
																			<p style="margin:0 0 16px"></p>
												
																			<h2
																				style="color:#2a3636;display:block;font-family:Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;font-size:18px;font-weight:bold;line-height:130%;margin:0 0 18px;text-align:left">
																				Customer's Message</h2>


																			<p style="margin:0 0 16px">
																				${message}
																				
																			</p>						
																		


																			<h2
																				style="color:#2a3636;display:block;font-family:Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;font-size:18px;font-weight:bold;line-height:130%;margin:0 0 18px;text-align:left">
																				Customer's details</h2>


																			<p style="margin:0 0 16px">
																				<strong>Email:</strong>
																				<a href="mailto:${userMail}"
																					style="color:#2a3636;font-weight:normal;text-decoration:underline"
																					target="_blank">${userMail}</a>
																			</p>


																			<p style="margin:0 0 16px">
																				<strong>Zipcode:</strong>
																					${zipcode}
																			</p>
																			<p style="margin:0 0 16px">
																				<strong>Country:</strong>
																				${country}
																				
																			</p>
																		</div>
																	</td>
																</tr>
															</tbody>
														</table>

													</td>
												</tr>
											</tbody>
										</table>

									</td>
								</tr>
							</tbody>
						</table>
					</td>
				</tr>
				<tr>
					<td align="center" valign="top">

						<table border="0" cellpadding="10" cellspacing="0" width="600"
							id="m_-1368018750309305127m_-32694759113509277template_footer">
							<tbody>
								<tr>
									<td valign="top" style="padding:0;border-radius:6px">
										<table border="0" cellpadding="10" cellspacing="0" width="100%">
											<tbody>
												<tr>
													<td colspan="2" valign="middle" id="m_-1368018750309305127m_-32694759113509277credit"
														style="border-radius:6px;border:0;color:#8a8a8a;font-family:Helvetica Neue,Helvetica,Roboto,Arial,sans-serif;font-size:12px;line-height:150%;text-align:center;padding:24px 0">
														<p style="margin:0 0 16px">Furniture BoutiQ</p>
													</td>
												</tr>
											</tbody>
										</table>
									</td>
								</tr>
							</tbody>
						</table>

					</td>
				</tr>
			</tbody>
		</table>
	</div>`

		let res = await sendMailByUser({ message: mailContent });

	}
	const onSubmit = async (value) => {
		if (appStatus.username !== undefined)
			value.email = appStatus.username;
		value.productID = productData.id;
	
		let res = await setQuote({ ...value, colour: colour ? colour : "None", size: size ? size : "None", action: "INSERT", actionValue: 0 });
		responseHelper(res, () => { setEnquiry(true); dispatch(setQuoteAction()); setKey(buttonKEY + 1); setDisable(true); sendMail(value) })
	}

	const getModalContentBeforeEnQ = () => {
		return (
			<>
				<Row gutter={[16, 16]} justify="" align="" className="p-1">
					<Col sm={24} xs={24} md={12} xl={6}>
						<ProductCard data={productData} noAction={true} />
					</Col>
					<Col sm={24} xs={24} md={18}>
						<Form form={form} onFinish={onSubmit} >
							<Row gutter={[16]}>
								{appStatus.username !== undefined ? null : <Col sm={24} xs={24} md={24} xl={8} >
									<Form.Item name="email" label="Email" rules={[{ required: true, message: "This is required" }, { type: 'email' }]}>
										<Input />
									</Form.Item>
								</Col>}
								<Col sm={12} xs={12} md={12} xl={8} >
									<Form.Item name="quantity" label="Quantity" rules={[{ required: true, message: "This is required" }]} >
										<InputNumber />
									</Form.Item>
								</Col>
								<Col sm={12} xs={12} md={12} xl={8}>
									<Form.Item name="country" label="Country" rules={[{ required: true, message: "This is required" }]}>
										<Input />
									</Form.Item>
								</Col>
								<Col sm={24} xs={24} md={24}>
									<Form.Item name="zipcode" label="Zipcode" rules={[{ required: true, message: "This is required" }]}>
										<InputNumber />
									</Form.Item>
								</Col>
								<Col sm={24} xs={24} md={24}>
									<Form.Item name="message" label="your Message" >
										<Input />
									</Form.Item>
								</Col>
							</Row>
							<Space align="start" direction="vertical" >
								<Reaptcha key={buttonKEY} sitekey="6Leo37YbAAAAABJYR-072waIh0N_-s22W4SyPSoG" onExpire={() => setDisable(true)}
									onError={() => setDisable(true)} size="" onVerify={() => setDisable(false)} />
								<Button className="mt-10" type="primary" htmlType="submit" disabled={disable} >
									Request
								</Button>
							</Space>
						</Form>
					</Col>
				</Row>
				<div className="p-1">
					<div class="buyer-protection__wrapper">
						<div class="buyer-protection-ico">
							<i></i>
							<span class="buyer-protection__title">Furniture Boutiq's Buyer Protection</span>
						</div>
						<p class="buyer-protection__description">
							Our dedicated account
							manager will be in touch to assist you with your enquiry.
							Furniture Boutiq's Buyer Protection gives you trade assurance and a secure purchasing
							experience.
						</p>
					</div>
				</div>
			</>
		);
	};

	const getModalContentAfterEnQ = () => {
		return (
			<div>
				<Row gutter={[16, 16]} >
					<Col sm={24} xs={24} md={12}>
						<ProductCard data={productData} noAction={true} />
					</Col>
					<Col sm={24} xs={24} md={12}>
						<h3>
							Your Product enquiry had been submitted successfully.
						</h3>
						<p>An account manager will be in touch with you shortly</p>
						<Button type="primary mt-20" onClick={() => his.push('/quote')} >View Enquiry</Button>
					</Col>
				</Row>
			</div>
		)
	}

	const onCancel = () => {
		setVisible(false);
		setEnquiry(false);
		form.resetFields();
	}

	return (
		<div>
			<Modal
				closable={false}
				visible={visible}
				onCancel={onCancel}
				footer={null}
				width="80%"
			>
				{getModalTitle("Request Quote", onCancel)}
				<div className="p-1">{enquiry ? getModalContentAfterEnQ() : getModalContentBeforeEnQ()}</div>
			</Modal>
			<Button
				onClick={() => setVisible(true)}
				style={{ width: '100%', margin: '20px 10px 0px 10px' }} type="primary">Request For Quote</Button>
		</div>
	);
};
