import { BulbOutlined, FileOutlined, HeartOutlined, HomeOutlined,
	 LoginOutlined, LogoutOutlined, MenuOutlined, SettingOutlined, UserOutlined, ShoppingCartOutlined } from "@ant-design/icons";

export const menuLayoutA = [
	{
		title: "Home",
		key: 1,
		type: "root",
		path: '/home',
		icon: <HomeOutlined />,
		view: "all"
	},
	{
		title: "Manage",
		key: 2,
		path: '/quote',
		type: "parent",
		icon: <FileOutlined />,
		children: [{
			title: "Quote",
			key: 3,
			path: '/quote',
			type: "child",
			icon: <MenuOutlined />,
			view: "all"

		},
		{
			title: "Order",
			key: 4,
			path: '/order',
			type: "child",
			icon: <ShoppingCartOutlined />,
			view: "all"

		},
		{
			title: "Favourite",
			key: 5,
			path: '/favourite',
			type: "child",
			icon: <HeartOutlined />,
			view: "all"

		}]
	},
	{
		title: "Inspire",
		key: 6,
		type: "parent",
		icon: <BulbOutlined />,
		children: [
			{
				title: "Blog",
				key: 7,
				path: '/blog',
				type: "child",
				icon: <FileOutlined />,
				view: "all"

			}
		]
	},
	{
		title: "Login",
		key: 8,
		type: "root",
		path: '/log-in',
		icon: <LoginOutlined />,
		view: "notLogin"
	},
	{
		title: "Sign Up",
		key: 9,
		type: "root",
		path: '/sign-up',
		icon: <UserOutlined />,
		view: "notLogin",
	},
	{
		title: "Profile",
		key: 10,
		type: "root",
		path: '/account',
		icon: <UserOutlined />,
		view: "login"
	},
	{
		title: "Logout",
		key: 11,
		type: "root",
		path: 'logout',
		icon: <LogoutOutlined />,
		view: "login",
	}
];
