import React from 'react'
import { Row, Col, Collapse } from 'antd'
import { useState } from 'react';
import { useEffect } from 'react';

export const Details = ({ data }) => {

	const [first, setFirst] = useState(null);
	const [second, setSecond] = useState(null);
	const [productData, setProductData] = useState(null)
	const [allDetails, setAllDetails] = useState(null)

	useEffect(() => {
		if (data.details) {
			data.details = data.details.replace(/\\n/g, "\\n")
				.replace(/\\'/g, "\\'")
				.replace(/\\"/g, '\\"')
				.replace(/\\&/g, "\\&")
				.replace(/\\r/g, "\\r")
				.replace(/\\t/g, "\\t")
				.replace(/\\b/g, "\\b")
				.replace(/\\f/g, "\\f");

			data.details = data.details.replace(/[\u0000-\u0019]+/g, "");
			const detailsData = JSON.parse(data.details);
			const indexToSplit = Math.ceil((detailsData.length) / 2);
			const first = detailsData.slice(0, indexToSplit);
			const second = detailsData.slice(indexToSplit);
			setAllDetails(detailsData)
			setProductData(data)
			setFirst(first);
			setSecond(second);
		}
	}, [data])

	const getTr = (data) => {
		return data.map(({ Features, Value }) =>
			<tr className="mb-20">
				<th scope="row" className="fnt-bold cs-product-sub-title" >{Features}</th>
				<td className="cs-product-sub-title">{Value}</td>
			</tr>
		)
	}

	const getDetails = (data) => {
		return (
			<Col md={12} xs={20} sm={20}>
				<table>
					<tbody>
						{getTr(data)}
					</tbody>
				</table>
			</Col>
		)
	}

	const getHeader = (value) => {
		return (
			<div className="mt-10 mb-10">
				<div className="detail-title ">{value}</div>
			</div>
		)
	}


	return (
		<div className="mt-30">
			<Collapse ghost expandIconPosition="right"  >
				<Collapse.Panel className="produc-des-collapse" header={getHeader("Description")} >
					{productData && <div className="p-1 cs-product-sub-title cs-des" dangerouslySetInnerHTML={{ __html: productData.longDescription }} />}
				</Collapse.Panel>
				<Collapse.Panel className="produc-des-collapse" header={getHeader("Features")} >
					<div className="p-1 cs-not-for-mobile">
						<Row gutter={[16, 16]} >
							<Col xs={24} md={12} xl={12} >
								{first ? getDetails(first) : null}
							</Col>
							<Col xs={24} md={12} xl={12} >
								{second ? getDetails(second) : null}
							</Col>
						</Row>
					</div>
					<div className="p-1 cs-only-for-mobile">
						<Row gutter={[16, 16]} justify="center" align="middle">
							<Col xs={24} sm={24} md={24} xl={24} >
								{allDetails ? getDetails(allDetails) : null}
							</Col>
						</Row>
					</div>
				</Collapse.Panel>
				<Collapse.Panel className="produc-des-collapse" header={getHeader("Why choose us")} >
					<div className="p-1">
						Our extensive experience has shaped us into a furniture manufacturer's professional: we
						can answer your concerns about furniture quality, construction, marketing, order processing, and so
						on. We can assist you with accurate and timely responses.
						<br />
						<br />
						At Furniture BoutiQ , you are not just another ‘customer'; you are special to us! We want you to be
						completely satisfied with your purchase. We, as a team, are here to serve you, and we want every
						interaction with you to be enjoyable and deserving of your loyalty.
						<br />
						<br />
						By developing and compiling the finest and latest interior design, we make it accessible and keep
						you updated about industry trends. This way shopping with us is fun , no worries! We assure you'll
						have a good time from start to finish—from shopping to gifting to shipping to loving.					</div>
				</Collapse.Panel>
				<Collapse.Panel className="produc-des-collapse" header={getHeader("Shipping")} >
					<div className="p-1">
						Our skilled artisans carefully handcraft each of our products. This requires time-consuming labour at
						our facilities in Jodhpur. Unlike the assembly line furniture seen at major shops, our final product is a
						one-of-a-kind piece of furniture.
						The processing time is 4-5 weeks (not including delivery time) and up to 6 weeks (not including
						delivery time) for custom orders, which incorporates order preparation, thorough quality assurance,
						and packaging.
						<br />
						<br />
						Your satisfaction is our goal, and we guarantee that, like thousands of other customers, you will find
						the wait to be well worth it!
						<br />
						<br />
						Our support and delivery teams will be in touch with you for a hassle-free delivery.
						Need something delivered sooner? We do have certain goods in stock, so please get in touch with
						our sales team.
						<br />
						<br />
						<strong>
							PLEASE NOTE : This information is subject to change at any time , so please check back with us
							regularly.
						</strong>				</div>
				</Collapse.Panel>
			</Collapse>
		</div>
	)
}

