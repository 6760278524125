import { blogImageURL } from "../../lib/constant";
export const imageMappingMain = {
    "Innovative Ways To Pep Your Coffee Table Vibe": `${blogImageURL}3/toa-heftiba-Q9OaF8CvMS0-unsplash-min.jpg`,
}

export const imageMapping1 = {
    "Simply Ways To Turn Your Bedroom Into A Sleep Oasis": `${blogImageURL}one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg`,
    "How To Professionally Decorate Your Home On A Budget!": `${blogImageURL}2/alexandra-gorn-W5dsm9n6e3g-unsplash-min.jpg`,
}
export const imageMapping2 = {
    "Dining Room Furniture": `${blogImageURL}4/angela-bailey-GfUk_pFtjz0-unsplash-min.jpg`,
    "Interior Design Secrets Only Professionals Know": `${blogImageURL}5/aaina-sharma-J8iOJZEPFQ4-unsplash-min.jpg`,
    "5 Ways To Create Your Dream Home Office On A Budget!": `${blogImageURL}6/annie-spratt-CW6ynEIgkEo-unsplash_1_11zon.jpg`,
    "How To Find Your Interior Design Persona": `${blogImageURL}7/armin-djuhic-mcL2f-J74GY-unsplash_1_11zon.jpg`,
    "5 Surprising Furniture Solutions For Small Apartments": `${blogImageURL}8/clay-banks-U74CPDcq5OY-unsplash_2_11zon.jpg`,
    // "Points To Bear In Mind When Purchasing A Sofa": `${blogImageURL}9/pexels-rachel-claire-4992590_13_11zon.jpg`,
    "6 Ways To Bring The Vintage Look Into Your Home": `${blogImageURL}10/alec-krum-Hl3fvUU48Lw-unsplash_1_11zon.jpg`,
}
const ads = {
    title: "",
    content: ``,
    images: []
}
export const info = {
    "Simply Ways To Turn Your Bedroom Into A Sleep Oasis": "It's no secret that colour has a profound effect on the human psyche.",
    "How To Professionally Decorate Your Home On A Budget!": "Bored of an old furniture piece? Think before tossing it off.",
}
export const blog1 = {
    "Simply Ways To Turn Your Bedroom Into A Sleep Oasis": [
        {
            title: "DECLUTTER",
            content: `<p>Chaos in your bedroom can result in chaos in your thoughts, therefore
            hampering a good night's sleep</p>
            <br />
            <p>  Only essentials and decorative pieces on your nightstand. Remember not to
            overcrowd your walls with photos. Serene art or pictures that give you a smile
            should be up there. Add to your room’s elegance bedside tables , floating
            nightstand – just the right place for your nightly essentials.</p>
            `,
            images: ["one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg", "one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg"]
        },
        {
            title: "CALM WITH COLOUR",
            content: `It's no secret that colour has a profound effect on the human psyche.
            Soothing colours that add a sense of calming presence in the room should be
            considered. Look for a colour wheel online for inspiration. Neutrals and calming
            blues are some of our recommendations.
            Tip : Not up for painting ? Think about the colour of your bedding.
            `,
            images: ["one/annie-spratt-V-6bv_-LzOo-unsplash-min.jpg", "one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg"]
        },
        {
            title: "A GOOD PILLOW",
            content: `It's no secret that your pillow has a major impact on how well you sleep.
            Whether it is soft or firm , don’t shy away from upgrading your pillows.
            Experiment to find a pillow that fits you best.
            Wash them with organic fabric fresheners to get the soothing fragrance while
            you sleep. `,
            images: ["one/beazy-toX2sYnycCw-unsplash-min.jpg", "one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg"]
        },
        {
            title: "MATTRESS AND BEDDING",
            content: `You spend one-third part of your life in bed , so consider investing in a high-
            quality mattress .When it comes to mattresses and bedding, we all have
            different inclinations. We stand with scientific studies that say newer mattress
            facilitates better sleep than an older model.Be it the classic cotton set or polyester sheets or luxurious linen sheets, don’t
            overlook your bedsheets! The texture can make or break your night’s sleep. `,
            images: ["one/chastity-cortijo-R-w5Q-4Mqm0-unsplash-min.jpg", "one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg"]
        }
        , {
            title: `BRING TRANQUILLITY : SCENTS , CRYTALS AND CALMING
            STONES`,
            content: `Fragrances like lavender, chamomile, vanilla, and jasmine in your bedroom help
            you relieve stress and rejuvenate at the end of a long day by stimulating your
            senses. An oil diffuser or scent spray on your pillows or near the bed is the way
            to go for a peaceful sleep.
            Incorporate crystals and calming stones like amethyst , rose quartz , selenite
            into your décor to set the tone for your bedroom setting and spirit.`,
            images: ["one/dani-costelo-IQXhLIoBA8g-unsplash-min.jpg", "one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg"]
        }
        , {
            title: "PLANTS",
            content: `Style your home decor and plants together. Greenery adds character and a
            healthy dimension.
            Plants are a great way to add a lovely, calming presence to your space.
            Bring in some live indoor plants and relax at night.
            Your bedroom can be transformed into the epitome of luxury with the addition
            of a dramatic lighting feature. Keep a warm tone lamp by your nightstand for
            the soothing dim light in your space , whether it’s for a night book reading or
            sleep over or just some gossip with your partner!
            `,
            images: ["one/emilio-garcia-_bzFLkJyY6o-unsplash-min.jpg", "one/andrea-davis-ytCOr2yDuBg-unsplash-min.jpg"]
        }
    ],
    "How To Professionally Decorate Your Home On A Budget!": [
        {
            title: "GO CRAZY WITH SPRAY",
            content: `Bored of an old furniture piece? Think before tossing it off. A simple DIY
            remedy of spray painting it can make a once boring furniture into an
            interesting rustic appealing piece.Grab a can of metallic gold or silver can have a go at your boring knobs.
            Often an overlooked piece , this tiny addition to your door can add a
            whole another element to your décor.`,
            images: ["2/annie-spratt-SAHhMV--UOQ-unsplash-min.jpg"]
        },
        {
            title: "NEW FLOORING",
            content: `Browsing for floor redo on the internet , but can’t seem to find one
            that fits your budget? What if we provide you with an even cheaper
            solution? Try Peel-and-stick flooring stickers (vinyl or any material you
            prefer) to dramatically improve the overall aesthetic appeal of your
            space.`,
            images: ["2/beazy-_9gxlIXdqDQ-unsplash-min.jpg"]
        }
        , {
            title: "CRYSTAL IT UP!",
            content: `It's easy to give your apartment a vintage charm by
            switching out knobs, drawer pulls, hinges, and anything
            else you can think of for eccentric retro models. You can
            even replace them with crystal doorknobs , drawer pulls
            giving an elegant appeal to your décor.`,
            images: ["2/ceyda-ciftci-dDVU6D_6T80-unsplash-min.jpg"]
        }
        , {
            title: "MIRROR , MIRROR ON THE WALL!",
            content: `Never can an interior designer overemphasize the
            importance of mirrors for an aesthetic appeal in a space.
            Add tiny mirrors or one big wall mirror or even a
            standing mirror anywhere in your place to make your
            décor classier.`,
            images: ["2/curology-ycEKahEaO5U-unsplash-min.jpg"]
        }
        , {
            title: "GO GREEN!",
            content: `Do not underestimate the power of greenery in your
            home. Add small plants in your bathroom , a big vase and
            dried flowers or faux plants in your living -dining area to
            generate a serene flair to your house.`,
            images: ["2/dylan-mcleod-R2Yxb5nEOTE-unsplash-min.jpg"]
        }
        , {
            title: "THROW SOME PILLOWS!",
            content: `Bored of the overly expensive couch you purchased a year
            ago? Buying a new one isn’t an option , we get it. Simply
            throw on some tiny cushions matching the overall taste of
            your décor and brighten up the living space.`,
            images: ["2/elena-kloppenburg-xnLHz7NJzRA-unsplash-min.jpg"]
        }
        , {
            title: "ACCENT RUG",
            content: `Cannot commit to the peel-and-stick-flooring idea? No
            worries , we’ve got you covered. Simply purchase a vintage
            , quirky rug to match your aesthetics of the space. But
            make sure to not spill your drinks there!`,
            images: ["2/frank-eiffert-KeiHJZWe-z8-unsplash-min.jpg"]
        }
        , {
            title: "FITTED BEDSHEET AND DUVETS TO THE RESCUE",
            content: `Tired of making bed every time because those bedsheets
            just keep slipping out? Fitted bedsheets are a Godsend for
            you , then! Throw on a duvet to make your bedroom look
            dapper.`,
            images: ["2/freddie-marriage-UcfKYTan-LU-unsplash-min.jpg"]
        }

    ],
    "Innovative Ways To Pep Your Coffee Table Vibe": [{
        title: "GO PLANTS!",
        content: `On a coffee table, a little greenery makes a huge difference. Incorporating a couple of potted plants
        or succulents to jazz up a tastefully styled coffee table scene. You can even go for dried flowers and
        faux stems for a tasteful, elegant look.
        Adding fresh flowers or plants to your decor instantaneously brightens it and tends to make it alive.
        Not only that, their fragrance will be a game changer for your living room vibe.`,
        images: ["3/daiga-ellaby-s313y8DpjSo-unsplash-min.jpg"]
    }, {
        title: "CANDLE AMBIANCE",
        content: `A "design secret weapon”, you can put a pretty candle in a glass pot or even DIY (seek inspiration
            from your favorite site) and you've got yourself a designer theme right on your table. It also works
            wonders for creating a relaxing atmosphere at home or when entertaining.`,
        images: ["3/goran-ivos-41cnAvPFP0A-unsplash-min.jpg"]
    }, {
        title: "RULE OF THREE",
        content: `Try creating odd number groupings when organizing your décor. Groups of three look the best (and
            are the quickest to organize).Now, don't take it too seriously. Don't limit yourself to only three objects on the surface of a coffee
            table (or sofa table or desk). Consider the Rule of Three to be three footprints on the surface.`,
        images: ["3/guillermo-nolasco-oC5NGvN3FOo-unsplash-min.jpg"]
    }, {
        title: "BOOK ME !",
        content: `Books are a great way to add efficient height and depth to your coffee table. Try a picture frame or
        decor piece (beads, faux stems) laid across the top book or you can go for layering – maybe fashion
        magazines in a pile.`,
        images: ["3/janko-ferlic-jmhwim1FUr4-unsplash-min.jpg"]
    }, {
        title: "ADD PERSONAL, DECORATIVE ITEMS TO THE MIX.",
        content: `This is where you take a flawlessly super set up (that could easily be in anyone's home) and transform
        it into your incredible set up. The coffee table is an excellent place to display small personal souvenirs
        that reflects your personality. Try stacking two to three items of varying sizes topped some journals or
        on a tray.`,
        images: ["3/jose-losada-JpB67VLxi7c-unsplash-min.jpg"]
    }, {
        title: "COLLECT THE GOODIES ON A TRAY",
        content: `Trays, in my opinion, are decorating secret weapons. A bunch of items on a desk or table can easily
        appear to be messy, but skillfully assemble them in a tray, and suddenly you have a presentable
        design scene that feels functional. They also add a little more personality and texture.`,
        images: ["3/kim-becker-wjPIu24U1yM-unsplash-min.jpg"]
    }, {
        title: "A VINTAGE AFFAIR",
        content: `A coaster adds style and function to your living space. The perfect place for your beverages, tea or
        late-night drinks with friends it prevents damage, scratches on glass, water marks and absorbing spills
        to keep your coffee table neat and perfect in appearance. Go for vintage coasters for a flair in the
        vibe.`,
        images: ["3/kristine-weilert-IIWWMziWMr4-unsplash-min.jpg"]
    }],
    "Dining Room Furniture": [{
        title: "Buried With Inexhaustible Options Of Dining Room Furniture Available That It Becomes Difficult In Choosing Your Ideal Set?",
        content: `Life happens in the dining room in more forms
        than one. A dinner party with friends, a multi-
        course dinner teamed with your favourite
        drinks, or a romantic candlelit dinner for two ,
        family play dates, studying and work, and, of
        course, late-night snacks and those never-
        ending discussions are all possibilities.
        This way it becomes critical that your dining set
        fulfil all of your day-to-day needs as well as
        your fancy hosting essentials. Don't worry ,
        here is where we come in. Here's how to
        choose dining room furniture to create the ideal
        hangout spot.`,
        images: ["4/angelo-pantazis--rXDAVb7tvA-unsplash-min.jpg"]
    }
        , {
        title: "RECOGNIZE YOUR DINING STYLE",
        content: `When selecting a dining table style, you should also consider the overall design aesthetic of your
        home. Your dining table should blend effortlessly into the aesthetic of your home, so it is best to
        closely examine its style. From traditional to rustic, industrial to classic, here are some styles to
        consider for a one-of-a-kind dining table while still balancing your overall decor.`,
        images: ["4/beazy-GAwEGMN_EMs-unsplash-min.jpg"]
    }
        , {
        title: "KNOW YOUR ROOM SIZE",
        content: `Consider the size of your family, the size of the room, and how you choose to use the table. Make
        sure you have enough chairs for everyone in your family and at least two extras for visitors. A small
        dining set can make your room look disjointed and waste space. If there is more than six feet
        between one wall or obstruction and the table, the set is too small.
        Tip : To ensure that guests sit comfortably, maintain the dining table's edge about 127 cm away from the walls and other
furniture. But remember not to place the dining set too far from the walls.`,
        images: ["4/beazy-JggpIf_rt4E-unsplash-min.jpg"]
    }
        , {
        title: "MATERIAL AND FINISHES",
        content: `With so many options available, deciding on the right material for your dining table can be challenging. One of the most
        important factors to consider when choosing a material is its effectiveness and longevity. The most popular materials
        used for dining tables are:`,
        images: ["4/charisse-kenion-EWqiAk7zHK4-unsplash-min.jpg"]
    }
        , {
        title: "THE HOT SEAT : CHAIRS",
        content: `Dining chairs are everything that contributes to the overall appearance of the dining table. Focus on comfort , style , ease of
        access and mobility when buying your chairs. Here are some ideas for you.
        Tip : Allow approximately 12 inches between the seat of the chair and the table. Most people will be able to fit comfortably in
this amount of space.`,
        images: ["4/chuttersnap-UhCT6p-yzx0-unsplash-min.jpg"]
    }
        , {
        title: "SPACE SAVING DINING",
        content: `If you have a larger family but limited seating space, a space-saving dining table is your go-to solution for
        making the dining area look neat and organised while being handy and efficacious. Look at some of the
        options:`,
        images: ["4/edgar-moran-56xUOS7rRHs-unsplash-min.jpg"]
    }
    ],

    "Interior Design Secrets Only Professionals Know": [
        {
            title: "GO BOLD WITH WALLPAPER",
            content: `Looking for a fool proof way to add instant omg aspect to a space?
            Whenever in doubt, make a dark room darker this enhances a space's
            natural atmosphere.
            Most people tend to overlook their bathroom’s wallpaper while thinking
            of décor. Do not make that mistake! Go bold or go home with wallpapers
            in your shower.`,
            images: ["5/andrea-davis-k8TlRnE61dw-unsplash-min.jpg"]
        },
        {
            title: "LARGE WALL DÉCOR FOR SMALL SPACE",
            content: `Just use large piece of art as a centrepiece in your home decor.
            One big art piece will make the place look wider and grander.
            Too many small pictures tend to make the space seem cluttered. Don’t worry if
            you have a tiny space , just simply utilise standard-sized furniture and a huge
            piece of art to your space appear larger.`,
            images: ["5/antho-tropo-jgBhFe0Ll6g-unsplash-min.jpg"]
        }
        , {
            title: "BE BOLD WITH PAINT",
            content: `According to the majority of designers, following the rules
            is boring. To create a truly one-of-a-kind home, one should
            take risks and trust their instincts. Only in this manner do
            the best interiors emerge.
            However, one suggestion is to not be afraid of using bright
            colours in the interior. One should be confident that their
            furniture and other accessories will complement the look .`,
            images: ["5/ellen-auer-HB5g37gDlic-unsplash-min.jpg"]
        }
        , {
            title: "MIRRORS ARE KEY",
            content: `Mirror , mirror on the wall!
            Mirrored screens generate a dramatic effect whilst also making
            your space appear bigger, livelier, and glitzier for everyday life, all
            the while still serving as a reflector for double-checking your
            eyeliner!`,
            images: ["5/h-wong-2rFFWJ-kpDg-unsplash-min.jpg"]
        }
        , {
            title: "OLD MAGAZINES AND BOOKS TO CREATE AFFORDABLE ART",
            content: `What if you can't afford those expensive paintings you've been eyeing
            in the gallery?
            Relax, we can't either!
            Take your favourite pictures from a vintage fashion magazine or one
            of those old books you never touch and cut them out instead.
            When you frame them and hang them on the wall, you'll see the
            magic begin to unfold.`,
            images: ["5/hedi-benyounes-M6oa-nJzSuI-unsplash-min.jpg"]
        }
        , {
            title: "VARY YOUR LIGHTS",
            content: `Create ideal lighting to boost the appeal of your interior design. This
            necessitates working on the ceiling light, source, and placement.Natural lighting is the safest and easiest option, but when it is scarce, you must
            replenish with artificial light sources.`,
            images: ["5/jean-philippe-delberghe-dLmlYKuoJBc-unsplash-min.jpg"]
        }
        , {
            title: "USE ARCHITECTURE AS A BASE",
            content: `Most people tend to misjudge their architecture base by going against their
            home’s pre-existing architectural imperfections rather than celebrating and
            highlighting them.A Parisian approach to interior design, and it's worth noting because those
            genuine aspects should be taken into consideration as design traits rather than
            flaws.`,
            images: ["5/kubra-acikgoz-xqw8rr8bUpo-unsplash-min.jpg"]
        }
    ],
    "5 Ways To Create Your Dream Home Office On A Budget!": [
        {
            title: "LIGHT",
            content: `ighting is one of the most important aspects of office space.
            Having a window in your office allows natural sunlight to overflow the space, so
            avoid using dark blinds or curtains.
            Rather, use a skinny fabric that will allow light to pass through even if you need
            to restrict out the scorching sun.`,
            images: ["6/carl-heyerdahl-KE0nC8-58MQ-unsplash_2_11zon.jpg"]
        },
        {
            title: "FURNITURE",
            content: `Once you've chosen a location for your office, it's time to start
            thinking about furniture. It's a great way to maximise the space in
            your room. It is possible to have a workspace wall mounted or into
            the room itself.
            Purchase an ergonomically designed chair in addition to a desk and
            storage options for your office. As you'll be sitting at your desk for the
            majority of the day, make sure you're comfy so you can stay focused
            and productive throughout the day. Too much sitting may cause
            strains and injuries, which can be prevented by using a comfortable
            chair.`,
            images: ["6/christopher-gower-vjMgqUkS8q8-unsplash_3_11zon.jpg"]
        }, {
            title: "PLANTS",
            content: `It is impossible to be satisfied and happy at work if your office space is
            lacklustre.The good news is that you can eliminate negative energy from your
            workspace by adding some office plants. Both you and the people around
            you will benefit from this.
            Incorporate plants like holy basil , money plant, peace lily.`,
            images: ["6/collov-home-design-UUsQk_9bdR8-unsplash_4_11zon.jpg"]
        }, {
            title: "CLUSTER FREE",
            content: `No one can work freely in an untidy environment. It just doesn’t set
            the right vibe for your office space. Consider putting shelves in your
            home or office.
            Using shelves to organise your home office is among the best ways to
            keep it clutter-free.Put your chargers, pictures, plants, and other items on shelves.`,
            images: ["6/ergonofis-oLThw11c8kg-unsplash_5_11zon.jpg"]
        }, {
            title: "COLOUR",
            content: `When it comes to decorating your home office, majority of you may be ditching the colour
            part. Didn’t you know that colours play an important hand in boosting your energy and
            efficiency.
            There are numerous options for home office colour schemes that will encourage efficiency.
            Go for light hues , for contrast black and white , light pink maybe. Go for whatever suits your
            eyes and relaxes your mind. But don’t overdo it. Let it stay calm and serene.`,
            images: ["6/gian-paolo-aliatis-hQvLc8KDN6A-unsplash_6_11zon.jpg"]
        }
    ],
    "How To Find Your Interior Design Persona": [{
        title: "IMMERSED UNDER MULTITUDE OF DÉCOR CHOICES? DON’T WORRY WE’VE GOT YOU COVERED",
        content: `Home design can be a difficult job. it is imperative to know your taste in interior
        designing early on. Look at our easy guide to know yours.`,
        images: ["7/frankie-x6vyL4YKP9c-unsplash_2_11zon.jpg"]
    }, {
        title: "Urban Industrial Style",
        content: `It is a refined version of the
        Industrial Design Style. It has
        become more refined and sleeker.
        One with a strong cosmopolitan
        flavour.`,
        images: ["7/jakob-owens-nQBc6clG3X4-unsplash_3_11zon.jpg"]
    }, {
        title: "Tropical / Botanical Trend",
        content: `A tropical
        inspired design that can be used whether or not your space
        is located in the tropics. Its back-to-
        nature and unplugged atmosphere is pleasant and soothing`,
        images: ["7/pexels-ksenia-chernaya-5806989_4_11zon.jpg"]
    }, {
        title: "SCANDANAVIAN STYLE",
        content: `This is the regional style OF Denmark, Finland, Sweden,
        Norway, and Iceland.
        The principles of simplicity and functionalism are central
        to the style. It's simple and uncluttered, with a focus on
        nature and natural materials.`,
        images: ["7/pexels-pixabay-271743_5_11zon.jpg"]
    }, {
        title: "Modern Cabin",
        content: `Cabins with a less rustic feel
        and a more polished overall
        appearance.`,
        images: ["7/pexels-rachel-claire-5865687_6_11zon.jpg"]
    }, {
        title: "Minimalist Style",
        content: `MINIMALISM is a philosophy, not an interior design style.
        It takes “LESS IS MORE” to a new level, based on the idea
        that a clogged space implies a clogged mind.`,
        images: ["7/pexels-valeria-boltneva-827518_7_11zon.jpg"]
    }, {
        title: "Hollywood Regency / Hollywood Glam Style",
        content: `A style influenced by Hollywood's golden age, which
        lasted from the 1920s to the 1960s.
        Features anything that radiates a lavish, cultured vibe,
        such as quintessential columns and flooring, glittering
        metals, pricey crystals, sumptuous velvet soft suede,
        and so on.`,
        images: ["7/shravankumar-hiregoudar-H69gTXqjzSA-unsplash_8_11zon.jpg"]
    }, {
        title: "Step 1 : What you like and what you don't like.",
        content: `Don't limit yourself to just one style. Perhaps the industrial style of one
        bedroom appeals to you, or the boho-minimalist style of the living room catches
        your eye—save both of them.`,
        images: ["7/sigmund-Y41w7pyxE18-unsplash_9_11zon.jpg"]
    }, {
        title: "Step 2 : Defining Your Style",
        content: `Your research is likely to portray some sort of structure now. Analyse what
        pictures you have now, and you'd find some common themes.
        Is it a blend of industrial and farmhouse? Or perhaps a modern chic bedroom
        with a tropical boho living space? Maybe a contemporary dining area but a
        retro kitchen? Or you realise you have created a entire new theme décor by
        yourself!`,
        images: ["7/tu-tu-QZGQO3NvsLo-unsplash_10_11zon.jpg"]
    }, {
        title: "Step 6 : Your style should have a mock-up",
        content: `As a result, there is a certain amount of unpredictability surrounding décor. Will
        you be able to achieve your goals, or will you fall short of them? Once achieved,
        will it be as pleasing to the eye as you had hoped it to be?
        As an alternative to buying a prototype of your décor, create virtual collages of
        the interior pieces you want and visualise how they would fit in your space.`,
        images: ["7/armin-djuhic-mcL2f-J74GY-unsplash_1_11zon.jpg"]
    }, {
        title: "Step 4 : Put a stamp on it!",
        content: `Brainstorming the name will give you real thrill , feeling like a hotshot interior designer! But
        don’t take too much time , you have important things left undone.
        Giving it a name will give you the necessary insight and vision of what you want your design
        to be. Once you've finished with the name, you'll know what to look for and make fewer
        mistakes about what to include and exclude.
        Begin decorating your home in your own unique style!`,
        images: ["6/pexels-cottonbro-4065620_12_11zon.jpg"]
    }],
    "5 Surprising Furniture Solutions For Small Apartments": [{
        title: "VERTICAL STORE",
        content: `You'll have to think outside the box when it comes to storage.
        The best piece of advice is to go vertical. If you have items
        that can be hung on the walls, hang them.Install racks, hangers, bolt panels, or whatever else is required
        to keep items off the floor.`,
        images: ["8/clay-banks-U74CPDcq5OY-unsplash_2_11zon.jpg"]
    },
    {
        title: "BED STORAGE",
        content: `Another way to add more ground-level storage is to move
        your furniture vertically. Consider raising a conventional bed
        with legs to create more storage underneath. Off-season
        clothing and extra sheets should be packed or towed.Sofa beds aren't new on the market, but they never go out of style in our
        homes. Purchase one for yourself!`,
        images: ["8/conor-sexton-FytRPOMijMA-unsplash_3_11zon.jpg"]
    },
    {
        title: "NARROW ENTRYWAY SOLUTION",
        content: `Yes, it's true that small spaces tend to have less storage space than larger
        spaces. But in order to give out your best my-home-is-so-organised vibe we
        provide you with a solution.
        This can be achieved by integrating narrow cabinets and other slim structures
        and processes, which can look equally sleek and sophisticated as the furniture
        around them does. In terms of style and physical fit, it's a simple matter of
        finding the right combination.`,
        images: ["8/darren-richardson-nzwaWv_Vnxw-unsplash_4_11zon.jpg"]
    },
    {
        title: "MOBILE FURNITURE",
        content: `In many situations, flexibility is essential. When it comes to furniture, you ought
        to be able to move things around and not be confined to a specific setup.`,
        images: ["8/devon-janse-van-rensburg-LUvdI4uCNnM-unsplash_5_11zon.jpg"]
    },
    {
        title: "LIVING ROOM STORAGE",
        content: `Ottomans in the living room can gracefully become the centre of attraction.
        They can easily give off a casual vibe in a rustic setting while also looking
        glamorous in an affluent ambience.
        They are known as living rooms are the interior world's hidden gems!
        Not just as an extra sitting place , these versatile units can be used as a great
        storage piece too.`,
        images: ["8/francesca-tosolini-lLDh9JppH2c-unsplash_6_11zon.jpg"]
    }],
    "6 Ways To Bring The Vintage Look Into Your Home": [{
        title: "RUGS : GLIMPSE IN THE PAST",
        content: `One can simply not mention rugs when thinking of vintage households. As the
        fashion has changed today , incorporating quirky rugs to your living space or dining
        area can prove to be a game changer for you. Accentuate your interior design in adelicate and old-world manner with vintage rugs that looks back in time and helps
        us recover a vintage look with a touch of contemporary flair.`,
        images: ["9/angelo-pantazis-N4wUsqIbMAM-unsplash_2_11zon.jpg"]
    },
    {
        title: "GO BRIGHT OR GO HOME!",
        content: `Give your walls a taste of those 1960’s – 70’s bright paint jobs. Let them grab
        the attention of every guest in the room. You can even add in a few walls art to
        match the delightfully upbeat look of the area.
        Numerous vintage home décor ideas that benefit from dark, rich colour
schemes. To enhance your home's vintage charm, use mystic colours such as
black, dark brown, navy blue, and slate grey.`,
        images: ["9/francesca-tosolini-9dyitA0YIHE-unsplash_3_11zon.jpg"]
    }
        , {
        title: "LIGHT ‘EM UP",
        content: `Yellow lighting is a must-have for any vintage-themed décor. If you want to
        create a vintage vibe in your rooms, you can choose antique tall lamps and
        bedside lamps with yellow lights. The room will be immersed in a soft, golden
        embrace.`,
        images: ["9/pexels-august-de-richelieu-4260639_4_11zon.jpg"]
    }, {
        title: "VINTAGE BEDROOM",
        content: `Choose a vintage headboard for your maybe-basic bed and give it a Hollywood
        makeover! This will definitely catch the eye of all your guests.Don’t forget to add a little zing of retro pieces to your room – maybe throw in a
        few vintage hangers for your shelf closet or a brightly blue coloured nightstand by
        the bed.`,
        images: ["9/pexels-blue-bird-7217785_5_11zon.jpg"]
    }, {
        title: "THRIFT STORE ANTIQUES",
        content: `You can create an air of nostalgia by decorating in a vintage style or bringing in
        antiques from the past. Ornate photo frames displayed on intricately carved
        wooden cabinets can be a wonderful addition to your living room.
        Add a small vanity brass vintage mirror to your dressing area or French gold
        mirror (DIY inspiration) on the wall to give a 1950’s Hollywood Glam vibe.Be it gramophones or vinyl record player will always add a touch of old-world
        charm.`,
        images: ["9/pexels-daria-shevtsova-962784 (1)_6_11zon.jpg"]
    }, {
        title: "ANTIQUE UTOPIA : COPPER BATHTUB",
        content: `Install a vintage copper bathtub to really add to the vibe. Its antique appeal
        lends the room a time-tested vintage character. In any bathroom, the rough
        bronze finish will make it a focal point. Copper bathtubs are a great option if
        you want to soak in a tub that transports you back in time.
        Add antiques storage containers in your bathroom to add a retro look.`,
        images: ["9/pexels-daria-shevtsova-1054974_7_11zon.jpg"]
    }],
  

}

