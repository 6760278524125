export const setActiveDashboard = "SET_ACTIVE_DASHBOARD";
export const getMembershipData = "GET_MEMBERSHIP_DATA";
export const getAccountData = "GET_ACCOUNT_DATA";
export const setParent = "SET_PARENT";
export const setMaster = "SET_MASTER";
export const setSubMaster = "SET_SUBMASTER";
export const setProduct = "SET_PRODUCT";
export const setUser = "SET_USER";
export const setOrder = "SET_ORDER";
export const setQuote = "SET_QUOTE";
export const setFavourite = "SET_favourite";
export const setMapping = "SET_MAPPING"
export const setParentSubMasterMapping = "SET_PARENT_SUBMASTER_MAPPING"
export const setLoadMore = "SET_LOAD_MORE"
