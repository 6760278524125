import React from 'react'

export const Terms = ({data}) => {
    return (
        <div>
            <h1>Terms and Condition</h1>
            {data && <div className="mt-20" dangerouslySetInnerHTML={{__html:data}} />}
        </div>
    )
}
