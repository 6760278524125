import React from 'react'
import './testimonial.css'
import { MainWrapper } from '../../lib/wrapper'
import OwlCarousel from 'react-owl-carousel';
import { userImageURL } from '../../lib/constant';

export const TestimonialPage = () => {

    const res = {
        0: {
            items: 1,
            nav: true
        },
        680: {
            items: 2,
            nav: true,
        },
        1000: {
            items: 3,
            nav: true
        }
    }

    return (
        <div className="testimonial-back">
            <MainWrapper>
                <div class="mobile-mt  gtco-testimonials">
                    <OwlCarousel className='owl-theme' margin={0} responsiveClass={true} responsive={res} >
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '2.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >Laurie King<br />

                                    </h3>
                                    <p class="card-text p-small">“ We bought our apartment unfurnished and just brought a few items from our one-
                                        bedroom apartment. We had no clue where to begin with all of this additional room on a
                                        tiny budget, but Furniture BoutiQ staff made it easy for us without burning a hole in our
                                        pockets. ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '3.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >Jim Kassidy <br />

                                    </h3>
                                    <p class="card-text p-small">“ Furniture BoutiQ was fantastic! I've bought a few things from them, including a customized
                                        entertainment centre, and they've all been of great quality. Anyone who comes to my home
                                        gives me a lot of compliments. In addition, their attention to detail is remarkable. You can
                                        see they are passionate about the final product.     ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '4.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >Alex Holt <br />

                                    </h3>
                                    <p class="card-text p-small">“ Our hallway needed a console table, and we found it , all thanks to Furniture BoutiQ. I really
                                        liked how the wood and metal were blended. To be honest, it really transformed the
                                        aesthetic of our home. Thank you very much! ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '1.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >Juliana Price <br />

                                    </h3>
                                    <p class="card-text p-small">“We've bought from Furniture BoutiQ twice already and both times we had amazing
                                        experiences. They feature high-quality furniture, a wide range of stylistic variations, and a
                                        reasonable price tag. We could not have asked for greater service from the personnel, who
                                        went above and beyond to ensure we found what we needed.” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '5.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >Russel Brown <br />

                                    </h3>
                                    <p class="card-text p-small">“Amazing! I'm quite impressed! From the packing to the final product, it's absolutely
                                        amazing, and I'm very satisfied with the quality. Our guests are drawn to the designer couch.
                                        Excellent staff and services! ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '6.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >John Clout <br />

                                    </h3>
                                    <p class="card-text p-small">“Working with Furniture BoutiQ relieves a lot of our stress and hassle, allowing us to
                                        concentrate on the client. Order project management takes a long time, especially when
                                        working with many suppliers and concurrent projects. Furniture BoutiQ helps me to find
                                        everything I need for a project and manage it all in one location, allowing me to work on
                                        many projects at the same time. It helps you save time by simplifying the sourcing and
                                        logistics of running a project. ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '48.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >Katherine Preston <br />

                                    </h3>
                                    <p class="card-text p-small">“For over 5 years Furniture BoutiQ has provided services to our company. Our office and
                                        conference room furniture has always looked new because to their excellent craftsmanship
                                        and timely attention to detail. We've had them handle leather and fabric upholstery for us,
                                        and we've always been satisfied! The staff is pleasant, accommodating, and always willing to
                                        assist! ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '7.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >Brad Moore <br />

                                    </h3>
                                    <p class="card-text p-small">“Furniture BoutiQ makes things easier for designers, which is great for us. The staff was very
                                        helpful and knowledgeable about the kind of pieces we wanted. They were very courteous
                                        and went out of their way to get us what we wanted. They further helped us with our design
                                        needs , which was pretty generous on their part.     ” </p>
                                </div>
                            </div>
                        </div>
                        <div>
                            <div class="card text-center">
                                {/* <img class="card-img-top" src={userImageURL + '8.jpg'} alt="" /> */}
                                <div class="card-body mt-10">
                                    <h3 className="m-0" >David Rudd <br />
                                    </h3>
                                    <p class="card-text p-small">““We'd like to thank the Furniture BoutiQ team for their efforts. You guys do fantastic work
                                        for us and make our job a lot simpler. We would be simply another interior designing firm if
                                        we didn't have partners like Furniture BoutiQ. ” </p>
                                </div>
                            </div>
                        </div>
                    </OwlCarousel>
                </div>
            </MainWrapper>
        </div>
    )
}
