import { history } from "../../config/history";

export const mangeLogin = (status, id, username) => {
  return (dispatch, getState) => {
    dispatch({
      type: "MANGE_LOGIN",
      payload: {status, username, id},
    });
  };
};
