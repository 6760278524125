import React from "react";
import { Row, Col } from "antd";
import { MainWrapper } from "../../lib/wrapper";
import { imageURL } from "../../lib/constant";

export const ImageSection = () => {
    return (
        <div className="mobile-mt image-section-back">
            <MainWrapper>
                <Row className="" gutter={[0, 16]} justify="center" align="middle">
                    <Col xs={24} sm={24} md={12}>
                        <img src={`${imageURL}Indivisual Customer for more time ssection.jpeg`} className="wh-100" alt="Asd" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <div className="mobile-p-90">
                            <h1 className="fnt-50 lh-58">For Retail Clients - B2C</h1>
                            <p className="p-small">
                                Looking for furniture for your house? You've come at the right place! We manufacture wooden
                                furniture and sell it all over the world.
                                <br />
                                <br />Have a vision for a customized piece? We are

                                happy to discuss it with you! We even offer customization on a single furniture order.
                                <br />
                                <br />
                                We carefully transport our furniture to your doorstep. The Furniture BoutiQ approach , which is
                                based on our extensive 'one-stop-shop' service , enables

                                purchasing any kind of furniture simple.
                                <br />
                                <br /> Please do not hesitate to place an order; simply email us a
                                product quote request ,

                                accept our proposal and pay for your order. Finally get it delivered at your doorstep.
                            </p>
                        </div>
                    </Col>
                </Row>
                <Row className="" gutter={[0, 16]} align="middle" >
                    <Col xs={24} sm={24} md={12} className="cs-only-for-mobile">
                        <img src={`${imageURL}Architecture in more time section.jpg`} className="wh-100" alt="Asd" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <div className="mobile-p-90">
                            <h1 className="fnt-50 lh-58">Interior Designers & Architectures</h1>
                            <p className="p-small">
                                Furniture BoutiQ operates in both the B2B and B2C segments. We have a vast clientele of interior
                                designer and architects. We pride ourselves on not only

                                manufacturing beautiful furniture but also on nurturing beautiful relations with our clients.
                                <br />
                                <br /> We
                                collaborated on a number of architecture and interior design projects.
                                <br />
                                <br />
                                When a client connects with us once, they don't look anywhere else for their furniture needs.
                                You can stay rest assured Furniture BoutiQ is the place for you!
                            </p>
                        </div>
                    </Col>
                    <Col xs={24} sm={24} md={12} className="cs-not-for-mobile">
                        <img src={`${imageURL}Architecture in more time section.jpg`} className="wh-100" alt="Asd" />
                    </Col>
                </Row>
                <Row className="" gutter={[0, 16]} align="middle">
                    <Col xs={24} sm={24} md={12}>
                        <img src={`${imageURL}interior Designer for more time .jpeg`} className="wh-100" alt="Asd" />
                    </Col>
                    <Col xs={24} sm={24} md={12}>
                        <div className="mobile-p-90">
                            <h1 className="fnt-50 lh-58">For Resellers</h1>
                            <p className="p-small">
                                Need stunning, fascinating furniture for your showrooms? We've got your back! We create items for
                                furniture showrooms and resellers all around the world. We produce a large number of furniture
                                and export it to our resellers.
                                <br />
                                <br />
                                We intent to form long-term relationships with furniture resellers , in addition to selling furniture
                                online. We are thrilled to work with experienced resellers on this.
                                <br />
                                <br />
                                Our ‘Affiliate program' may be of interest to you if you own a furniture or decorative objects
                                business.
                            </p>
                        </div>
                    </Col>
                </Row>
            </MainWrapper>
        </div>
    );
};
