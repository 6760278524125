import React from 'react'
import { AppController } from '../../appController/AppController'
import { MainWrapper } from '../../lib/wrapper'
import './style.css'
import { ProductCard } from '../../components/cards/productCard'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { setLoadMore, setProducAction, setSubMasteAction } from '../../redux/actions/allData'
import { useLocation } from 'react-router-dom'
import { Button } from 'antd'
import { uniqBy } from 'lodash'
export const ProductPage = ({ history }) => {

    const dispatch = useDispatch()
    const allData = useSelector(state => state.allDataReducer)
    const [data, setData] = useState(null)
    const [count, setCount] = useState(20);
    const [subMasterdata, setsubMasterdata] = useState({ name: 'loading...' })
    let location = useLocation();

    useEffect(() => {
        if (allData.product.length) {
            let subMasterID = location.pathname.split('/')[2]
            let res = allData.product.filter(data => data.parentID === subMasterID) || []
            setData(uniqBy(res, "id"))
        } else dispatch(setProducAction())

        if (allData.subMaster.length) {
            const subMasterID = window.location.pathname.split('/')[2]
            let res = allData.subMaster.filter(data => data.id === subMasterID)[0]
            setsubMasterdata(res)
        } else dispatch(setSubMasteAction())
        // setCount(20)
    }, [allData, allData.product, allData.subMaster, location])

    useEffect(() => {
        window.onscroll = function () { scrollFunction() };
    }, [])

    useEffect(()=>{
        if(allData && allData.loadMore[location.pathname]){
            setCount(allData.loadMore[location.pathname])
        }else setCount(20)
    },[location])


    function scrollFunction() {
        var mybutton = document.getElementById("myBtn");
        if (mybutton && mybutton.style) {
            if (document.body.scrollTop > 20 || document.documentElement.scrollTop > 20) {
                mybutton.style.display = "block";
            } else {
                mybutton.style.display = "none";
            }
        }
    }

    const getFilters = () => {
        return (
            <div></div>
        )
    }

    const getProducts = () => {
        return (
            <div>jkj</div>
        )
    }


    return (
        <div>
            <AppController history={history}>
                <div style={{ position: 'relative' }}>
                    <div id="top" />
                    <MainWrapper>
                        <div style={{ textAlign: 'center' }} className="mt-20">
                            <h1 className="app-title">{subMasterdata.name || "Loading..."}</h1>
                            <p className="res-p" > {subMasterdata.description || "Loading..."}</p>
                        </div>
                        <div className="mt-30 mb-30 grid">
                            {data !== null && data.filter((_,index) => index < count).map(productData =>
                                <ProductCard history={history} data={productData} noAction={false} />
                            )}
                        </div>
                        {(data !== null && count < data.length) &&  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} >
                            <Button onClick={() => {dispatch(setLoadMore({[location.pathname]: count + 40 })); setCount(count + 40)}} >Load More</Button>
                        </div>}
                    </MainWrapper>
                    <div style={{ position: 'fixed', right: '25px', bottom: '103px', zIndex: 999999999 }} >
                        <Button style={{ display: 'none' }} id="myBtn" onClick={() => document.getElementById("top").scrollIntoView({
                            behavior: 'smooth'
                        })} >Top</Button>
                    </div>
                </div>
            </AppController>
        </div >
    )
}
