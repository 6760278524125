import React from "react";
import { Layout, Row, Col, Divider } from "antd";
import {
	EyeInvisibleOutlined, FacebookOutlined, FileDoneOutlined, InstagramOutlined,
	LinkedinOutlined, MailOutlined, SendOutlined,
	TrademarkOutlined, TwitterOutlined, UserOutlined,
	UserSwitchOutlined, PicCenterOutlined
} from '@ant-design/icons'
import "./style.css";
import logo from '../../assets/images/logo.png';
import pin from '../../assets/pinterest.png'

const { Footer } = Layout;

export const FooterPage = ({ history }) => {
	return (
		<div>
			<Footer>
				<Row gutter={16} justify="space-between" align="top">
					<Col xs={24} sm={24} md={24} lg={6}>
						<div className="d jc-c mb-10" ><img style={{ width: '60%' }} src={logo} alt={"logo"} /></div>
						<Divider className="for-mobile" />
					</Col>
					<Col xs={24} sm={6} md={6} lg={6}>
						<ul className="footer-ul">
							<li className="footer-li" onClick={() => history.push('/pages/about-us')} > <UserOutlined className="mr-10" />About Us</li>
							<li className="footer-li" onClick={() => history.push('/pages/contact-us')} ><UserSwitchOutlined className="mr-10" />Contact Us</li>
							<li className="footer-li"  > <a href="mailto:FB@FURNITUREBOUTIQ.COM">
								<MailOutlined className="mr-10" /> Email Us
							</a></li>
							<li className="footer-li"  ><a href="mailto:FB@FURNITUREBOUTIQ.COM">
								<SendOutlined className="mr-10" />Request Callback
							</a></li>
						</ul>
						<Divider className="for-mobile" />
					</Col>
					<Col xs={24} sm={6} md={6} lg={6}>
						<ul className="footer-ul">
							<li className="footer-li" onClick={() => history.push('/pages/terms-condition')} ><FileDoneOutlined className="mr-10" />Terms & Condition</li>
							<li className="footer-li" onClick={() => history.push('/pages/privacy-policy')} ><EyeInvisibleOutlined className="mr-10" />Privacy Policy</li>
							<li className="footer-li" onClick={() => history.push('/to-the-trade')} ><TrademarkOutlined className="mr-10" />To The Trade</li>
						</ul>
						<Divider className="for-mobile" />
					</Col>
					<Col xs={24} sm={6} md={6} lg={6}>
						<ul className="footer-ul">
							<li className="footer-li" onClick={() => window.open("https://Instagram.com/Furnitureboutiq", '_blank')} ><InstagramOutlined className="mr-10" />Instagram</li>
							<li className="footer-li" onClick={() => window.open("https://twitter.com/BoutiqFurniture?s=20", '_blank')} ><TwitterOutlined className="mr-10" />Twitter</li>
							<li className="footer-li" onClick={() => window.open("https://m.facebook.com/Itsfurnitureboutiq/", '_blank')} ><FacebookOutlined className="mr-10" />Facebook</li>
							<li className="footer-li" onClick={() => window.open("https://www.linkedin.com/company/furnitureboutiq", '_blank')} ><LinkedinOutlined className="mr-10" />Linkedin</li>
							<li className="footer-li" onClick={() => window.open("https://pin.it/2FbJ60R", '_blank')} ><img style={{ width: 14, height: 14, opacity: .5 }} src={pin} className="mr-10" />Pinterest</li>
						</ul>
					</Col>
				</Row>
				<Row justify="center">
					<Col span={20} style={{ textAlign: 'center', opacity: .6 }}>
						Registered in India, Basni 342005 | {new Date().getFullYear()} Furniture Boutiq © All Rights Reserved
					</Col>
				</Row>
			</Footer>
		</div>
	);
};
