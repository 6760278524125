import React from "react";
import { Row, Col, Divider } from "antd";

export const StatsPage = () => {
    return (
        <div className="mobile-mt">
            <Row gutter={[16, 16]} justify="center" align="middle">
                <Col xs={24} sm={24} md={12}>
                    <div className="p-2">
                        <h1 className="cs-sub-title">Customization</h1>
                        <p className="p-small">
                            "Your imagination , Our creation" is our ethos when we think of customization. Customize your
                            furniture item at Furniture BoutiQ according to your needs.
                            <br />
                            <br />
                            We offer a variety of solid wood, industrial, and leather furniture designs, as well as decorative
                            products. At Furniture BoutiQ customize your furniture

                            pieces as you envision them , suiting your space and requirement. Our furniture is designed to fit
                            your space or meet your needs. We provide simple

                            customization process with minimal price range. With our skilled artisans' vast experience and
                            renowned craftmanship we aid you in attaining your dream furniture.
                            <br />
                            <br />
                            If you wish to build a design that isn't on our list , then share your concept with us, and our
                            experienced team of artists will turn your imagination into reality.
                        </p>
                    </div>
                </Col>
                <Col xs={24} sm={24} md={12}>
                    <Row gutter={[16, 16]} justify="center" align="middle">
                        <Col xs={24} sm={24} md={12}>
                            <div className="stats-card display-column">
                                <p className="pre-title">PRODUCTS</p>
                                <div className="d" style={{ textAlign: "center" }}>
                                    <h2 className="fnt-72 m-0 lh-86">5000</h2>
                                    <h3 className="mt-10 fnt-40 lh-46">+</h3>
                                </div>
                                <Divider />
                                <p className="p-small">
                                    To meet their ever-increasing insatiable need for diversity,
                                    we offer our clients an untapped spectrum of products for
                                    their furniture needs
                                </p>
                            </div>
                            <div className="stats-card display-column primary-back">
                                <p className="pre-title">ORDERS</p>
                                <div className="d" style={{ textAlign: "center" }}>
                                    <h2 className="fnt-72 m-0 lh-86">100</h2>
                                    <h3 className="mt-10 fnt-40 lh-46">+</h3>
                                </div>
                                <Divider />
                                <p className="p-small">
                                    From pleased clients in enterprises to happy homes for our
                                    consumers , we process an average of 1000+ orders every month
                                </p>
                            </div>
                        </Col>
                        <Col xs={24} sm={24} md={12}>
                            <div className="stats-card display-column orange-back">
                                <p className="pre-title">COUNTRIES</p>
                                <div className="d" style={{ textAlign: "center" }}>
                                    <h2 className="fnt-72 m-0 lh-86">80</h2>
                                    <h3 className="mt-10 fnt-40 lh-46">+</h3>
                                </div>
                                <Divider />
                                <p className="p-small">
                                    Today, we sell our “Made in Jodhpur” products to over 20
                                    countries across the world, along with comprehensive design
                                    solutions for any environment: home, workplace, hotel,
                                    restaurant, and interior space.
                                </p>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    );
};
