import React, { useState } from 'react'
import { Form, Input, Row, Col, Button } from 'antd'
import { change, changePassword, sendMail } from '../../elements/api/other';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

const { Item } = Form

export const PasswordForm = ({ data }) => {
    const [form] = Form.useForm();
    const [mailSent, setMailSent] = useState(false)
    const [success, setSuccess] = useState(false)
    const appStatus = useSelector(state => state.appStatus)

    const onSubmit = async ({ oldPassword, password, password2 }) => {
        if (password2 !== password) {
            toast.error("Password doesn't match.");
        } else {
            let resp = await change({ password, email: appStatus.username, oldPassword })
            if (resp.status === "1") {
                setSuccess(true)
            } else {
                toast.error("There some error , please check old password once")
            }
        }
    }
    return (
        <div>
            {success ?
                <h1>Successfully changed password.</h1>

                : <Form form={form} onFinish={onSubmit}  >
                    <Row gutter={[16, 16]} className="mb-30">
                        <Col xs={24} sm={24} md={24} xl={24} >
                            <Item name="oldPassword" label="Old Password"
                                rules={[{ required: true, message: "Old Password is required" }]} >
                                <Input style={{ width: '50%' }} />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} >
                            <Item name="password" label="New Password" rules={[{ required: true, message: "Password is required" }]} >
                                <Input style={{ width: '50%' }} />
                            </Item>
                        </Col>
                        <Col xs={24} sm={24} md={24} xl={24} >
                            <Item name="password2" label="Confirm Password" rules={[{ required: true, message: "Password is required" }]} >
                                <Input style={{ width: '50%' }} />
                            </Item>
                        </Col>
                        <Button type="primary" htmlType="submit">
                            Change
                        </Button>
                    </Row>
                </Form>}
        </div>
    )
}
