import React, { useState } from 'react'
import { AppController } from '../../appController/AppController'
import { Main } from './Main'
import { CatCard } from './CatCard'
import { MainWrapper } from '../../lib/wrapper'
import './style.css'
import { StatsPage } from './stats'
import { ChoseUsPage } from './choseUs'
import { ImageSection } from './ImageSection'
import { TestimonialPage } from './Testimonial'
import { Modal } from 'antd'
import blackFridayPc from './B.png'
import blackFridayMonile from './bf400.png'

export const HomePage = ({ history }) => {
    const [isVisible, setIsVisible] = useState(false);
    
    return (
        <div>
            <AppController history={history}>
                <Modal
                    closable={false}
                    visible={isVisible}
                    footer={null}
                    title={null}
                    onCancel={()=>setIsVisible(false)}
                >
                    <div className="cs-not-for-mobile">
                        <img style={{width:'100%'}} src={blackFridayPc} />
                    </div>
                    <div className="cs-only-for-mobile">
                        <img style={{width:'100%'}} src={blackFridayMonile} />
                    </div>
                </Modal>
                <MainWrapper >
                    <Main history={history} />
                    <CatCard his={history} />
                    <StatsPage />
                    <ChoseUsPage />
                </MainWrapper>
                <ImageSection />
                <TestimonialPage />
            </AppController>
        </div>
    )
}
