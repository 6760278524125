import React from 'react'
import { Form, Input } from 'antd';
import { login, sendMailByUser, signUp } from '../../elements/api/other';
import { responseHelper } from '../../lib/response';
import { mangeLogin } from '../../redux/actions/appStatus';
import { useDispatch, useSelector } from 'react-redux'
import { useEffect } from 'react';
import './style.css'
import logo from '../../assets/images/logo.png'
import { mailTemplate } from '../../lib/welcomeMail';

export const SignUp = ({ history }) => {

    const dispatch = useDispatch()
    const appStatus = useSelector(state => state.appStatus)

    useEffect(() => {
        if (appStatus.login) {
            history.push('/')
        }
    }, [appStatus.login])

    const onSubmit = async (value) => {
        let res = await signUp({ ...value })
        responseHelper(res, () => loginHelp(value))
    }
    const loginHelp = async (value) => {
        let res = await login({ ...value })
        responseHelper(res, () => {
            localStorage.setItem("d59517ae-e16e-11eb-ba80-0242ac130004", true);
            localStorage.setItem("id", res.payload.id);
            localStorage.setItem("username", res.payload.username);
            sendWelcomeMail(res.payload.username)
            dispatch(mangeLogin(true, res.payload.id, res.payload.username))
        })
    }

    const sendWelcomeMail = async (username) => {
        const message = mailTemplate("")
        await sendMailByUser({userMail:username, subject:"Furniture BoutiQ warmly welcomes you at our E-commerce platform", message})

    }
    return (
        <div className="app-static-wrapper">
            <div className="app-static-logo">
                <img alt="logo" src={logo} style={{ width: '15%' }} />

            </div>
            <Form id="login-form" onFinish={onSubmit} >
                <div className="app-form-full">
                    <div className="app-form-label">Email *</div>
                    <Form.Item name="email"  rules={[{ required: true, message: "Email is required" }, {type:'email'}]}>
                        <Input />
                    </Form.Item>
                </div>
                <div className="app-form-full">
                    <div className="app-form-label">Password *</div>
                    <Form.Item name="password" rules={[{ required: true, message: "Password is required" }]}>
                        <Input />
                    </Form.Item>
                </div>
                <div class="app-form-full">
                    <a id="forgot-password-link" href="/password-reset">Forgotten password?</a>
                </div>
                <div class="app-form-full">
                    <button htmlType="submit" form="login-form" class="app-cta-btn primary">
                        Sign Up
                    </button>
                </div>
                <div class="app-form-full">
                    <a id="new-to-company-link" href="/log-in" class="app-cta-btn secondary">
                        Log In
                    </a>
                </div>
            </Form>
        </div>
    )
}
