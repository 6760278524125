import React, { useEffect, useState } from "react";
import { Layout, Affix, Popover, Row, Col, Menu, Input } from "antd";
import { MenuUnfoldOutlined, MenuFoldOutlined, CaretRightOutlined, AlignLeftOutlined, BulbOutlined, SearchOutlined, CloseOutlined } from "@ant-design/icons";
import { SideMenu } from "../components/sideMenu/SideMenu";
import { WebHeader } from "../components/webHeader";
import { MegaMenu } from "../components/megaMenu";
import { FooterPage } from "../components/footer";
import { useDispatch } from "react-redux";
import { setProductMapping } from "../redux/actions/allData";
import "./appController.css";
import { useSelector } from "react-redux";
import logo from '../assets/images/whiteLogo.png'

const { Content } = Layout;
const { SubMenu } = Menu;

export const AppController = ({ history, children, callback }) => {
	const [collapsed, setcollapsed] = useState(false);
	const [isSearch, setIsSearch] = useState(false)
	const [popoverVisible, setPopoverVisible] = useState(false)
	const [searchIn, setSearchIn] = useState('All Category')
	const [data, setData] = useState([])
	const [subProductKey, setSubProductKey] = useState(0)

	const allDataReducer = useSelector(state => state.allDataReducer)
	const appStatus = useSelector(state => state.appStatus)

	const toggle = (val) => {
		setIsSearch(false)
		setPopoverVisible(false);
		setcollapsed(val);
	};

	const dispatch = useDispatch()
	useEffect(() => {
		if (allDataReducer.productMapping.length === 0)
			dispatch(setProductMapping())
	}, [])

	useEffect(() => {
		if (allDataReducer.productMapping) {
			setData(allDataReducer.productMapping)
		}
	}, [allDataReducer.productMapping])

	useEffect(() => { setPopoverVisible(false) }, [callback])

	const getFormobile = (data) => {

		const handleClick = ({ key }) => {
			setSearchIn(key.split('__')[0]);
			setSubProductKey(key.split('__')[1]);
			setPopoverVisible(false)
		};

		return (
			<Menu
				// className="cs-only-for-mobile"
				onClick={handleClick}
				// style={{ backgroundColor: "#5b7070" }}
				defaultSelectedKeys={['sub1']}
				theme="light"
				mode="inline"
			>
				{
					data.map(parentData =>
						<SubMenu style={{ width: '100%' }} key={parentData.id} title={parentData.name}>
							{
								parentData.children.map(masterData =>
									<SubMenu style={{ width: '100%' }} key={masterData.id} title={masterData.name}>
										{masterData.children.map(subMasterData =>
											<Menu.Item style={{ backgroundColor: '#f5f5f5', margin: 0 }} key={`${subMasterData.name}__${subMasterData.id}`}>{subMasterData.name}</Menu.Item>
										)}
									</SubMenu>
								)
							}
						</SubMenu>
					)
				}
			</Menu>
		)
	}

	const handleSearch = (val) => {
		setIsSearch(false)
		history.push(`/search/${subProductKey}/${val}`)

	}

	const getMobileBuggerMenu = history => {
		const inspireContent = (
			<div className="header-item-drop" style={{ height: 'calc(100vh - 80px)' }} >
				<Row justify="center">
					<Col span={20}>
						<div className="d ai-center mt-20">
							<Input.Search className="center-button" onSearch={handleSearch} size="large" placeholder="serach in category" allowClear={true} prefix={<SearchOutlined />} />
							<Popover visible={popoverVisible} content={getFormobile(data)} placement="bottomLeft" trigger="click">
								<div className="search-cat-mobile" onClick={() => { window.scrollTo(0, 0); setPopoverVisible(!popoverVisible); setcollapsed(false); }} >
									{searchIn}
								</div>
							</Popover>
						</div>
					</Col>
				</Row>
			</div>
		);

		return (
			<div className="cs-only-for-mobile mobile-header">
				<div className="d">
					<div className="d-flex ai-center jc-c header-item " style={{ textAlign: 'center', height: '100%' }} >
						<div onClick={() => toggle(!collapsed)} className={"mr-20 d-flex ai-center jc-c  "} style={{ textAlign: 'center', height: '100%' }}>
							<AlignLeftOutlined className="" style={{ fontSize: 20, marginLeft: '1em' }} />
						</div>
					</div>
					<div className="d jc-c ai-center" style={{ margin: '20px 20px 20px 0px' }}>
						<div style={{ width: 1, height: '100%', backgroundColor: '#e4fcee', opacity: .7 }} />
					</div>
					<div style={{ textAlign: 'center', height: '100%' }}>
						<Popover overlayClassName="cs-search" overlayStyle={{ left: 0, width: '100%' }} visible={isSearch} trigger="click" content={inspireContent} placement="bottomLeft">
							<div onClick={() => { window.scrollTo(0, 0); setIsSearch(!isSearch) }} className={"mr-20 d-flex ai-center jc-c header-item " + (isSearch && "header-item-active")} style={{ textAlign: 'center', height: '100%' }}>
								{!isSearch ? <SearchOutlined className="" style={{ fontSize: 22 }} /> : <CloseOutlined style={{ fontSize: 22 }} />}
							</div>
						</Popover>
					</div>
				</div>
				<div className="d jc-sb  ai-center" style={{ textAlign: 'center', height: '100%' }}>
					<div className="d-flex ai-center jc-c mr-20 ml-20" style={{ textAlign: 'center', height: '100%' }}>
						<img style={{ width: 120 }} className="cur" onClick={() => history.push('/')} src={logo} alt="logo" />
					</div>
				</div>
			</div>
		);
	};

	const getWebHeader = () => {
		return <WebHeader history={history} />;
	};

	const getFooter = () => {
		return <FooterPage history={history} />;
	};

	return (
		<Layout>

			<Layout style={{ position: 'relative' }} className={"site-layout " + ((isSearch || collapsed) && "over-hid")}>
				<header className="app-header">
					{getMobileBuggerMenu(history)}
					{getWebHeader()}
				</header>

				{!collapsed && <MegaMenu history={history} />}
				<Content
					style={{
						//  margin: "24px 16px",
						//  marginLeft: 12,
						//  marginRight: 12,
						padding: 0,
						height: collapsed ? "100vh" : "100%",
						minHeight: 280
					}}
				><div style={{ position: collapsed ? '' : 'inherit' }} >
						{collapsed && <div className="cs-only-for-mobile" >
							<SideMenu history={history} collapsed={collapsed} setcollapsed={setcollapsed} />
						</div>}
						<div className="cs-center">
							{children}
						</div>
					</div>
				</Content>
				{getFooter()}
			</Layout>
		</Layout>
	);
};
