import { toast } from "react-toastify";
import { encodeParams, modifyFetch } from "../../lib/apiHelper";

let USER_URL = "https://furnitureboutiq.com/api/user";
let MAIL_URL = "https://furnitureboutiq.com/api/mail";
let ORDER_URL = "https://furnitureboutiq.com/api/order";
let QUOTE_URL = "https://furnitureboutiq.com/api/quote";
let FAVOURITE_URL = "https://furnitureboutiq.com/api/favourite";
let ADMIN_URL = "https://furnitureboutiq.com/api/admin";
let ADDRESS_URL = "https://furnitureboutiq.com/api/address";
let EXTRA_URL = "https://furnitureboutiq.com/api/extra";
let SLIDER_URL = "https://furnitureboutiq.com/api/slider";


export const setUser = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${USER_URL}/user.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const getUser = async () => {
	const response = await modifyFetch(`${USER_URL}/user.php?${encodeParams({ user: 'ADMIN' })}`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const setOrder = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${ORDER_URL}/order.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const getOrder = async () => {
	const response = await modifyFetch(`${ORDER_URL}/order.php`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const getOrderById = async (value) => {
	const response = await modifyFetch(`${ORDER_URL}/order.php?${encodeParams({ ...value })}`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const setQuote = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${QUOTE_URL}/quote.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const getQuote = async () => {
	const response = await modifyFetch(`${QUOTE_URL}/quote.php`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const setFavouriteList = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${FAVOURITE_URL}/list.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const setFavouriteItem = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${FAVOURITE_URL}/item.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const getFavouriteItem = async () => {
	const response = await modifyFetch(`${FAVOURITE_URL}/item.php`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}
export const getFavouriteItemByID = async (value) => {
	const response = await modifyFetch(`${FAVOURITE_URL}/item.php?${encodeParams({ ...value })}`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const getFavouriteListById = async (value) => {
	const response = await modifyFetch(`${FAVOURITE_URL}/list.php?${encodeParams({ ...value })}`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}



export const changePassAdmin = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${ADMIN_URL}/admin.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const login = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${USER_URL}/login.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};


export const signUp = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${USER_URL}/signup.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};


export const setAddress = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}

	const response = await modifyFetch(`${ADDRESS_URL}/address.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const getAddress = async (value) => {
	const response = await modifyFetch(`${ADDRESS_URL}/address.php?${encodeParams({ ...value })}`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const getExtra = async () => {
	const response = await modifyFetch(`${EXTRA_URL}/extra.php`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const sendMail = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${USER_URL}/sendForgotPasswordMail.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const changePassword = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${USER_URL}/changePassword.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};

export const change = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${USER_URL}/change.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};


export const getSlider = async () => {
	const response = await modifyFetch(`${SLIDER_URL}/slider.php`);
	const body = await response.json()
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
}

export const sendMailByUser = async (query) => {
	let formData = new FormData();
	for (var key in query) {
		formData.append(key, query[key]);
	}
	const response = await modifyFetch(`${MAIL_URL}/mail.php`, {
		method: 'POST',
		body: formData
	});
	const body = await response.json();
	if (response.status !== 200) { toast.error(body.message || "some error occured, please try sometime later"); }
	return body;
};
