import React, { useState } from 'react'
import { AppController } from '../../appController/AppController'
import { MainWrapper } from '../../lib/wrapper'
import { Row, Col, Form, Button, Input, Space } from 'antd'
import { sendMailByUser, signUp } from '../../elements/api/other'
import { responseHelper } from '../../lib/response'
import Reaptcha from 'reaptcha'
const { Item } = Form

export const MemberPage = ({ history }) => {
    const [success, setSuccess] = useState(false)
    const [disable, setDisable] = useState(true)
    const onSubmit = async (value) => {
        let res = await signUp({ email: value.email, password: value.password })
        const second = async () => {
            let message = "";
            for (let key of Object.keys(value)) {
                message += `${key} : ${value[key]} \n`
            }
            let resp = await sendMailByUser({ message })
            responseHelper(resp, () => setSuccess(true))
        }
        responseHelper(res, () => second())
    }


    return (
        <div>
            <AppController history={history}>
                <MainWrapper>
                    <div>
                        <h1 className="fnt-40 lh-46">
                            Become A Member
                        </h1>
                        {success ? <div>
                            <h3>Successfully sent your query.</h3>
                            <Button className="mt-10" type="primary" onClick={() => history.push('/')} >Back to home</Button>
                        </div> : <Form onFinish={onSubmit} >
                            <Row justify="center" gutter={[16, 16]} style={{ padding: `0em ${window.innerWidth <= 1110 ? "1em" : "10em"}` }} >
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item name="firstName" label="First Name" className="m-0">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item className="m-0" name="lastName" label="Last Name">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item className="m-0" name="email" rules={[{ required: true, message: "Email is required" }]} label="Email">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item className="m-0" name="password" rules={[{ required: true, message: "Password is required" }]} label="Password">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={24}>
                                    <Item className="m-0" name="phone" label="Phone">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={24}>
                                    <Item className="m-0" name="address1" label="Address Line 1">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={24}>
                                    <Item className="m-0" name="address2" label="Address Line 2">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item className="m-0" name="city" label="City">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item className="m-0" name="state" label="State">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item className="m-0" name="zip" label="Zipcode">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={12} sm={12} md={12} xl={12}>
                                    <Item className="m-0" name="country" label="Country">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Col xs={24} sm={24} md={24} xl={24}>
                                    <Item className="m-0" name="website" label="Website">
                                        <Input style={{ width: "100%" }} />
                                    </Item>
                                </Col>
                                <Space align="start" direction="vertical">
                                    <Reaptcha sitekey="6Leo37YbAAAAABJYR-072waIh0N_-s22W4SyPSoG" onExpire={() => setDisable(true)}
                                        onError={() => setDisable(true)} size="" onVerify={() => setDisable(false)} />
                                    <Button type="primary" htmlType="submit" disabled={disable} >
                                        Create Account
                                    </Button>
                                </Space>
                            </Row>
                        </Form>}
                    </div>
                </MainWrapper>
            </AppController>
        </div>
    )
}
