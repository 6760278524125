import React, { useEffect, useState } from 'react'
import { Form, Input } from 'antd';
import { changePassword, login, sendMail } from '../../elements/api/other';
import { responseHelper } from '../../lib/response';
import { mangeLogin } from '../../redux/actions/appStatus';
import { useDispatch, useSelector } from 'react-redux'
import './style.css'
import logo from '../../assets/images/logo.png'

export const ForgetPassword = ({ history }) => {
	const [mailSent, setMailSent] = useState(false)
	const [mail, setMail] = useState("");
	const [success, setSuccess] = useState(false)

	const dispatch = useDispatch()
	const appStatus = useSelector(state => state.appStatus)

	useEffect(() => {
		if (appStatus.login) {
            history.push('/')
		}
	}, [appStatus.login])


	const onSubmit = async ({ email, otp, password }) => {
		if (mailSent) {
			let res = await changePassword({ email, password, otp })
			if (res.status === "1")
				setSuccess(true)
		} else {
			let res = await sendMail({ email })
			if (res.status === "1")
				setMailSent(true);
		}
	}


	return (
		<div className="app-static-wrapper">
			<div className="app-static-logo">
				<img  alt="logo" src={logo}   style={{width:'15%'}}/>
			</div>
			{success ?
				<div className="display-column">
					<h1>Successfully changed password.</h1>
					<div class="app-form-full">
						<a id="new-to-company-link" href="/log-in" class="app-cta-btn secondary">
							Log in
						</a>
					</div>
				</div>
				: <Form id="login-form" onFinish={onSubmit} >
					{!mailSent ? <h4>Please enter your mail in order to change password.</h4> :
					<h4>Please check your inbox.</h4>}

					<div className="app-form-full">
						<div className="app-form-label">Email *</div>
						<Form.Item name="email" rules={[{ required: true, message: "Email is required" }]}>
							<Input type="email" />
						</Form.Item>
					</div>

					{mailSent && <>
						<div className="app-form-label">OTP *</div>
						<div className="app-form-full">
							<Form.Item name="otp" rules={[{ required: true, message: "OTP is required" }]}>
								<Input />
							</Form.Item>
						</div>
						<div className="app-form-full">
							<div className="app-form-label">Password *</div>
							<Form.Item name="password" rules={[{ required: true, message: "Password is required" }]}>
								<Input />
							</Form.Item>
						</div></>}
					{mailSent ? <div class="app-form-full">
						<button htmltype="submit" id="login-form" class="app-cta-btn primary">
							Submit
						</button> </div>
						:
						<div class="app-form-full">
							<button htmltype="submit" id="login-form" class="app-cta-btn primary">
								Send OTP
							</button>
						</div>}
					<div class="app-form-full">
						<a id="new-to-company-link" href="/log-in" class="app-cta-btn secondary">
							Log in
						</a>
					</div>
				</Form>}
		</div>
	)
}

