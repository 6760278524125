import React from "react";
import { Tabs, Table, Steps } from "antd";
import { AppController } from "../../appController/AppController";
import { MainWrapper } from "../../lib/wrapper";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProducAction, setQuoteAction } from "../../redux/actions/allData";
import { uploadFileUrl } from "../../lib/constant";

const { TabPane } = Tabs;

const { Step } = Steps;

export const QuotePage = ({ history }) => {

	const [data, setData] = useState(null);
	const allData = useSelector(state => state.allDataReducer)
	const appStatus = useSelector(state => state.appStatus)
	const dispatch = useDispatch()

	useEffect(() => {
		if (allData.quote && allData.product.length) {
			let temp = allData.quote.filter(data => data.email === appStatus.username);
			setData(temp)
		} else {
			dispatch(setQuoteAction())
			dispatch(setProducAction())
		}
	}, [allData.quote, allData.product])

	const getProductCard = (data) => {
		let id = data.productID;
		let quantity = data.quantity;
		let productData = allData.product.filter(data => data.id === id)[0];
		if (productData) {
			const images = productData.images.split(',');

			return (
				<div>
					<div className="d ai-center cs-not-for-mobile">
						<div className="image-preview">
							<img onClick={() => history.push(`/product/${id}/${productData.parentID}/${productData.name}`)}
								style={{ height: '100%' }}
								src={uploadFileUrl + images[0]} alt={productData && productData.name} />
						</div>
						<div className="ml-10" >
							<div>{productData.name}</div>
							<div>Quanity: {quantity}</div>
						</div>
					</div>
					<div className="display-column ai-center cs-only-for-mobile">
						<div className="image-preview">
							<img onClick={() => history.push(`/product/${id}/${productData.parentID}/${productData.name}`)}
								style={{ height: '100%' }}
								src={uploadFileUrl + images[0]} alt={productData && productData.name} />
						</div>
						<div className="ml-10" >
							<div>{productData.name}</div>
							<div>Quanity: {quantity}</div>
						</div>
					</div>
				</div>
			)
		}
		else return "This product has been deleted"
	}

	const getActionValue = (id) => {
		return (
			<div>
				<Steps className="cs-not-for-mobile" progressDot size="small" direction="horizontal" current={parseInt(id)}>
					<Step title="Waiting" />
					<Step title="In Progress" />
					<Step title="Finished" />
				</Steps>
				<Steps className="cs-only-for-mobile" progressDot size="small" direction="vertical" current={parseInt(id)}>
					<Step title="Waiting" />
					<Step title="In Progress" />
					<Step title="Finished" />
				</Steps>
			</div>
		)
	}

	const columns = [
		{
			title: 'Product Name',
			render: (data) => { return <div>{getProductCard(data)}</div> },
			width: '40%',
		},
		{
			title: 'Quote info',
			dataIndex: 'id',
			key: 'age',
			render: (id) => <div>Quote #{id}</div>
		},
		{
			title: 'Status',
			dataIndex: 'actionValue',
			render: (data) => getActionValue(data),
			width: '35%'
		},
	];
	return (
		<div>
			<AppController history={history}>
				<MainWrapper>
					<Tabs defaultActiveKey="1" type="card" size={"large"}	>
						<TabPane tab="Quote" key="1">
							<div className="p-1" style={{ backgroundColor: '#f5f5f5' }}  >
								<div className="mb-10 ">
									Here you can see all your quotes.
								</div>
								{data && <Table scroll={{ x: 500 }} dataSource={data} columns={columns} />}
							</div>
						</TabPane>
					</Tabs>
				</MainWrapper>
			</AppController>
		</div>
	);
};
