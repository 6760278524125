import React, { useEffect } from "react";
import { Tabs, Row, Col } from "antd";
import { AppController } from "../../appController/AppController";
import { MainWrapper } from "../../lib/wrapper";
import { useLocation } from "react-router-dom";
import { useState } from "react";
import { AboutUS } from "./about";
import { Privacy } from "./privacy";
import { ContactUs } from "./contactUs";
import { Terms } from "./terms";
import { Collabrate } from "./collabrate";
import { getExtra } from "../../elements/api/other";
import { aboutImageURL } from "../../lib/constant";

const { TabPane } = Tabs;

export const ExtraPage = ({ history }) => {
    const [page, setPage] = useState("about-us");
    const [data, setData] = useState(null);

    const location = useLocation();
    useEffect(() => {
        const page = location.pathname.substring(7);
        document.getElementById('top').scrollIntoView({ behavior: 'smooth' })
        getExtra().then(res => {
            if (res.status === "1") {
                setData(res.data[0])
            }
        }).catch(err => console.log(err))
        setPage(page);
    }, [location]);


    return (
        <div>
            <AppController history={history}>
                <MainWrapper>
                    <Row justify="start" gutter={[16, 16]} className="mt-20 mb-20">
                        <Col span={24}>
                            <h2 className="app-title m-0">Know About Us.</h2>
                        </Col>
                        {/* <Col span={24}>
                            <h3 className="app-title">
                                For Exclusive Trade Price - Become A Member
                            </h3>
                        </Col> */}
                        {/* <Col span={24}>
                            <img
                                style={{ width: '100%' }}
                                alt="main-banner"
                                id="listing-banner"
                                src={aboutImageURL + "1.jpg"}
                            />
                        </Col> */}
                    </Row>
                    <Tabs activeKey={page} onChange={(val) => setPage(val)} type="card" id="top" >
                        <TabPane className="p-1" tab="About Us" key="about-us">
                            <AboutUS />
                        </TabPane>
                        {/* <TabPane className="p-1" tab="Collabrate with Us" key="collabrate">
                            <Collabrate  data={data && data.collabrate} />
                        </TabPane> */}
                        <TabPane className="p-1" tab="Terms & Condition" key="terms-condition">
                            <Terms data={data && data.terms} />
                        </TabPane>
                        <TabPane className="p-1" tab="Privacy Policy" key="privacy-policy">
                            <Privacy data={data && data.privacy} />
                        </TabPane>
                        <TabPane className="p-1" tab="Contact Us" key="contact-us">
                            <ContactUs history={history} data={data && data.contact} />
                        </TabPane>
                    </Tabs>
                </MainWrapper>
            </AppController>
        </div>
    );
};
