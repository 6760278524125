import React from 'react'
import { Row, Col } from 'antd'
import './style.css'
import { imageURL } from '../../lib/constant';
import { useEffect } from 'react';

const col = ["#dee9ef", "#eee5db", "#f3f0d0"];
const i = 0
const imagesMap = {
    "Bar Cabinets.png": "480+",
    "Bed.png": "290+",
    "Bookcases.png": "250+",
    "Buffets & Sideboards.png": "800+",
    "Coffee Table.png": "560+",
    "Dining Chairs.png": "280+",
    "Dining Table.png": "330+",
    "Dining Table & Sets.png": "280+",
    "Dressers.png": "470+",
    "Leather Sofa.png": "180+",
    "Media Console.png": "340+",
    "Wardrobe.png": "290+",
}

const linkMap = {
    "Bar Cabinets.png": "35",
    "Bed.png": "16",
    "Bookcases.png": "27",
    "Buffets & Sideboards.png": "13",
    "Coffee Table.png": "21",
    "Dining Chairs.png": "10",
    "Dining Table.png": "9",
    "Dining Table & Sets.png": "8",
    "Dressers.png": "19",
    "Leather Sofa.png": "29",
    "Media Console.png": "25",
    "Wardrobe.png": "17",
}

export const CatCard = ({ his }) => {

    useEffect(() => {
        document.getElementById('cs-cat').scrollLeft += 20;
    }, [])

    const getCard = (idx, data) => {
        return (
            <div onClick={()=>his.push(`/category/${linkMap[data]}/${data.slice(0, -4).split(' ').join('-')}`)}  key={idx} style={{ backgroundColor: col[idx % 3], height: '100%' }} className="d ai-center p-1 jc-sa cat-card-wrapper cur">
                <Row justify="space-between">
                    <Col span={12}
                    >
                        <div className={"cat-card-image-wrapper" + (idx % 2 !== 0 ? "" : "-down")}>
                            <img className="" style={{ width: '70%' }} src={imageURL + "/category/" + data} alt={data} />
                        </div>
                    </Col>

                    <Col span={12}>
                        <div className={"display-column cat-card-text-wrapper" + (idx % 2 === 0 ? "" : "-down")}>
                            <p style={{ opacity: .8 }} className="m-0 fnt-40 lh-46">{imagesMap[data]}</p>
                            <p>{data.slice(0, -4)}</p>
                        </div>
                    </Col>
                </Row>

            </div>
        )
    }

    const images = [
        "Bar Cabinets.png",
        "Bed.png",
        "Bookcases.png",
        "Buffets & Sideboards.png",
        "Coffee Table.png",
        "Dining Chairs.png",
        "Dining Table.png",
        "Dining Table & Sets.png",
        "Dressers.png",
        "Leather Sofa.png",
        "Media Console.png",
        "Wardrobe.png",
    ]


    return (
        <div className="mobile-mt ">
            <div className="cs-only-for-mobile">
                <Row gutter={[16, 16]} id="cs-cat" className="no-scroll-bar " style={{ display: 'flex', flexWrap: 'nowrap', overflowX: 'scroll', height: 125 }}>
                    {images.map((data, key) =>
                        <Col key={key} sm={20} xs={20} md={6}  >
                            {getCard(key, data)}
                        </Col>
                    )}
                </Row>
            </div>
            <Row gutter={[16, 16]} id="cs-cat" className="no-scroll-bar cs-not-for-mobile" style={{ display: 'flex' }}>
                {images.map((data, key) =>
                    <Col key={key} sm={12} xs={12} md={6}>
                        {getCard(key, data)}
                    </Col>
                )}
            </Row>
        </div>
    )
}
