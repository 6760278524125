import React from "react";
import { Row, Col } from "antd";
import { aboutImageURL } from "../../lib/constant";

export const AboutUS = () => {
  return (
    <div>
      <Row gutter={[16, 16]} justify="center" align="middle">
        <Col span={24} className="p-1">
          {/* <h2>Our Aim is to Make Sourcing for Interior Products Easy!</h2> */}
          <div>
            We initially opened our doors as a small manufacturing company, the
            company's philosophy was to provide high-quality products at an
            affordable price. Initially aiming for exporters as clients, we
            collaborated with experienced artisans and designers who created
            handcrafted and traditional pieces employing their years of
            experience and knowledge. Once purchased, our reasonably priced
            quality items were sold globally by exporters at a very high profit
            margin, resulting in sizeable profits for themselves.
          </div>
        </Col>
        {/* <Col span={24} >
                    <img
                        style={{ width: '100%' }}
                        alt="03 ipad view maya armchair"
                        class="image-responsive image-thumbnail __web-inspector-hide-shortcut__"
                        src={aboutImageURL + '2.jpg'}
                    />
                </Col> */}
        <Col span={24}>
          <div>
            As our company transitioned under new ownership in 2017, we revamped
            our philosophy. The innovative business concepts of our founder
            created the foundation of what we are today as a company. The idea
            of dealing with commercial projects directly, collaborating with
            architects and designers, customizing projects at minimal prices
            established the tone.
          </div>
        </Col>
        {/* <Col span={24}  className="cs-only-for-mobile">
                    <img
                        style={{ width: '100%' }}
                        alt="03 ipad view maya armchair"
                        class="image-responsive image-thumbnail __web-inspector-hide-shortcut__"
                        src={aboutImageURL + '4.jpg'}
                    />
                </Col> */}
        <Col span={24}>
          <div>
            Craftsmanship isn't simply a catchphrase or an advertising concept
            for us; it's at the core of everything we do. Consumers had two
            options few years ago. They could either buy cheaply produced
            furniture or spend a lot on handcrafted quality items. We aim to
            provide a diverse range of high-quality wooden furniture at
            reasonable rates. We opted to promote entirely online and pass the
            savings on to our consumers after calculating the figures and
            considering all of our choices.
          </div>
        </Col>
        {/* <Col span={24} >
                    <img
                        style={{ width: '100%' }}
                        alt="03 ipad view maya armchair"
                        class="image-responsive image-thumbnail __web-inspector-hide-shortcut__"
                        src={aboutImageURL + '3.jpg'}
                    />
                </Col>
                <Col span={24}  className="cs-not-for-mobile">
                    <img
                        style={{ width: '100%' }}
                        alt="03 ipad view maya armchair"
                        class="image-responsive image-thumbnail __web-inspector-hide-shortcut__"
                        src={aboutImageURL + '4.jpg'}
                    />
                </Col> */}
        <Col span={24}>
          <div>
            Today , our label , Furniture BoutiQ is a prominent player in the
            furniture industry for home, interior, and hotel furniture products.
            We have flourished throughout time by tailoring both customization
            services and product categories to the demands and tastes of its
            customers. The company has been expanding over the past many years
            bringing on board a plethora of skilled specialists: architects,
            designers, interior architects, engineers, and project managers. You
            will acknowledge our passion for each project once you purchase or
            collaborate with the us.
          </div>
        </Col>
      </Row>
    </div>
  );
};
