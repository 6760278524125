import React from "react";
import { Tabs, Steps, Row, Col, Button, Modal, Timeline } from "antd";
import { AppController } from "../../appController/AppController";
import { MainWrapper } from "../../lib/wrapper";
import { useEffect } from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setProducAction } from "../../redux/actions/allData";
import { uploadFileUrl } from "../../lib/constant";
import './style.css'
import { getAddress, getOrderById } from "../../elements/api/other";
import { getModalTitle } from "../../lib/extra";

const { TabPane } = Tabs;

const { Step } = Steps;

export const OrderPage = ({ history }) => {

    const [data, setData] = useState(null);
    const [orderData, setOrderData] = useState(null)
    const allData = useSelector(state => state.allDataReducer)
    const appStatus = useSelector(state => state.appStatus)
    const [modalVisible, setModalVisible] = useState(false)
    const [trackData, setTrackData] = useState([])
    const [selectedID, setSelectedID] = useState(null)
    const [profile, setProfile] = useState(null)

    const dispatch = useDispatch()

    useEffect(() => {
        if (selectedID) {
            setModalVisible(true)
            let track = orderData.filter(data => data.id === selectedID)[0]['track'];
            if (track) {

                track = track.replace(/\\n/g, "\\n")
                    .replace(/\\'/g, "\\'")
                    .replace(/\\"/g, '\\"')
                    .replace(/\\&/g, "\\&")
                    .replace(/\\r/g, "\\r")
                    .replace(/\\t/g, "\\t")
                    .replace(/\\b/g, "\\b")
                    .replace(/\\f/g, "\\f");

                track = track.replace(/[\u0000-\u0019]+/g, "");
                track = JSON.parse(track)
                setTrackData(track)
            }
        }
    }, [selectedID])


    useEffect(() => {
        if (allData.product.length) {
            setData(allData.product)
        } else {
            dispatch(setProducAction())
        }
        initP()
        init()
    }, [allData.product])

    const initP = () => {
        getAddress({ parentID: appStatus.id })
            .then(res => {
                if (res.status === "1") {
                    setProfile(res.data[0])
                }
            }).catch(err => console.log(err))
    }

    const init = async () => {
        let res = await getOrderById({ id: appStatus.id })
        if (res.status === "1") {
            res.data = res.data.map(dat => {
                let productid = dat.productID.split(',')
                let price = dat.price.split(',')
                let quantity = dat.quantity.split(',');
                let product = [];
                let total = 0
                for (let i = 0; i < productid.length; i++) {
                    total += parseInt(price[i]) * parseInt(quantity[i])
                    product.push({
                        product: productid[i],
                        price: price[i],
                        quantity: quantity[i]
                    })
                }
                return {
                    ...dat, product, total
                }
            })
            // console.log(res.data);
            setOrderData(res.data)
        }
    }

    const close = () => {
        setSelectedID(null)
        setModalVisible(false)
        setTrackData(null)
    }

    const getProductCard = (product, obj) => {
        // let id = data.productID ;
        // let id = "144";
        // let quantity = data.quantity;
        let quantity = product.quantity;
        let productData = data.filter(data => data.id === product.product)[0];
        // console.log("prod", product.product, productData);
        if (productData) {
            const images = productData.images.split(',');

            return (
                <div>
                    <div className="d">
                        <div className="image-preview">
                            <img onClick={() => history.push(`/product/${productData.product}/${productData.parentID}/${productData.name}`)}
                                style={{ height: '100%' }}
                                src={uploadFileUrl + images[0]} alt={productData && productData.name} />
                        </div>
                        <div className="ml-10" >
                            <div>{productData.name}</div>
                            <div>Quanity: {quantity}</div>
                            <div>SKU: {productData.sku}</div>
                            <div className="mt-10">Price: {`${obj.priceSection === "dollar" ? "$" : "₹"} ${product.price}`}</div>

                        </div>
                    </div>
                </div>
            )
        }
        else return "This product has been deleted"
    }

    const getModalContent = () => {
        // console.log(trackData);
        return (
            <div>
                <Timeline mode={"left"}>{
                    trackData && trackData.map(
                        data =>
                            <Timeline.Item label={data.location}>{data.status}</Timeline.Item>
                    )
                }

                </Timeline>
            </div>
        )
    }

    return (
        <div>
            <AppController history={history}>
                <MainWrapper>
                    <Modal
                        closable={false}
                        visible={modalVisible}
                        onCancel={close}
                        footer={null}
                    >
                        {getModalTitle("Track details", close)}
                        <div className="p-1">
                            {getModalContent()}
                        </div>

                    </Modal>
                    <Tabs defaultActiveKey="1" type="card" size={"large"}	>
                        <TabPane tab="Order" key="1">
                            {data && <div className="p-1" style={{ backgroundColor: '#f5f5f5' }}  >
                                <div className="mb-10 ">
                                    Here you can see all your orders.
                                </div>
                                {orderData && orderData.map(obj => <div className="p-1" >
                                    <Row gutter={[16, 16]} justify="space-between" align="middle" className="top" >
                                        <Col span={6} >
                                            <div>Order ID: {obj.id}  </div>
                                            <div>Order Date: {obj.created_on}</div>
                                        </Col>
                                        <Col span={6} >
                                            <div className="cur" onClick={() => history.push('/account')} >Ship To :  {`${profile && profile.firstName} ${profile && profile.lastName}`}</div>
                                        </Col>
                                    </Row>
                                    <Row justify="space-between" className="product"
                                        gutter={[16, 16]}
                                        align="top" >
                                        <Col xs={24} sm={24} md={18} xl={18} >
                                            {obj.product.map(singleData => getProductCard(singleData, obj))}
                                            <div>Payment Status: {obj.paymentStatus}</div>
                                        </Col>
                                        <Col xs={24} sm={24} md={2} xl={3} >
                                            <div className="display-column">
                                                <Button type="primary" onClick={() => setSelectedID(obj.id)} >
                                                    Track Package
                                                </Button>
                                            </div>
                                        </Col>
                                    </Row>
                                    <Row gutter={[16, 16]} justify="end" className="total" >
                                        <Col span={6} >Order Total:  {obj.total} </Col>
                                    </Row>
                                </div>)}
                            </div>}

                        </TabPane>
                    </Tabs>
                </MainWrapper>
            </AppController>
        </div>
    );
};
