import React from 'react'
import { AppController } from '../../appController/AppController'
import { MainWrapper } from '../../lib/wrapper'
import { imageMapping1, imageMapping2, imageMappingMain, info } from './mainContent'
import { Col, Row } from 'antd'
import _map from 'lodash/map'
import { XMasonry, XBlock } from "react-xmasonry"; // Imports JSX plain sources
import './style.css'
import './main.css'

const newMain = (history) => {
    return (
        <div className="wrapper">
            <div className="d " style={{ flexWrap: 'wrap' }} >
                <Row gutter={[16, 16]} >
                    {_map(imageMapping1, (value, key) =>
                        <Col xs={24} md={12} xl={12} >
                            <div className="article-featured-img bg-cover">
                                <img className="opc cur"
                                    onClick={() => history.push(`/blog/${key.trim().split(' ').join('-')}`)} src={value} alt={key}
                                    style={{ width: '90%', maxHeight: 500 }} />
                            </div>
                            <div className="w-430">
                                <div className="sm-headline caps mt-30">Lifestyle</div>
                                <h3 onClick={() => history.push(`/blog/${key.trim().split(' ').join('-')}`)}
                                    className="mt-10 fnt-40 lh-46 fnt-grey cur">{key}</h3>
                                <div className="mt-15">
                                    {info[key]}
                                    <img src="https://studio-mcgee.com/app/themes/studio-mcgee/dist/images/icon-arrow_bbb76b6e.svg" alt="Read More"
                                        className="read-more" />
                                </div>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </div >
    )
}


export const BlogPage = ({ history }) => {
    let count = 0
    return (
        <AppController history={history}>
            <div className="d">
                {_map(imageMappingMain, (value, key) => <Row className="bg-tan-1">
                    <Col xs={24} xl={12} md={12}  >
                        <a onClick={() => history.push(`/blog/${key.trim().split(' ').join('-')}`)} >
                            <div className="bg-cover h-100 hero-slider-img"
                                style={{ backgroundImage: `url('${value}')` }}>
                                <img style={{ width: '100%' }}
                                    src={value} alt="" className="show-md" />
                            </div>
                        </a>
                    </Col>
                    <Col xs={24} xl={12} md={12}  >
                        <div className="col-md-6 hero-content" onClick={() => history.push(`/blog/${key.trim().split(' ').join('-')}`)}>
                            <div className="w-430 margin-auto">
                                <div className="sm-headline caps">Design</div>
                                <a >
                                    <h2 className="headline-1 mt-10">{key}</h2></a>
                                <div className="mt-10">
                                    <a >
                                        <img src="https://studio-mcgee.com/app/themes/studio-mcgee/dist/images/icon-arrow_bbb76b6e.svg" alt="Read More" className="read-more" />
                                    </a>
                                </div>
                            </div>
                        </div>
                    </Col>
                </Row>)}
            </div>
            <div className="sec-headline caps align-center headline-3 border-top-norm">
                The latest
            </div>
            <MainWrapper>
                {newMain(history)}
                <XMasonry className="mobile-mt">
                    {
                        _map(imageMapping2, (value, key) => {

                            var wid = [1, 1.5, 1, 1, 2, 1, 1];
                            return (
                                <XBlock width={wid[count++]} >
                                    <div
                                        className="cur"
                                        onClick={() => history.push(`/blog/${key.trim().split(' ').join('-')}`)} style={{ width: '100%', padding: 10 }}>
                                        <div className="article-featured-img bg-cover">
                                            <img style={{ width: "100%" }}
                                                src={value}
                                                alt={key} />
                                        </div>
                                        <div className="w-430">
                                            <div className="sm-headline caps mt-10">Lifestyle</div>
                                            <h2 className="grid-headline-sm mt-10">{key}</h2>
                                        </div>
                                    </div>
                                </XBlock>
                            )
                        })
                    }
                </XMasonry>
            </MainWrapper>

        </AppController >
    )
}
