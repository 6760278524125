import { Row, Col, Button, Skeleton, Select, Breadcrumb } from 'antd'
import React, { useEffect } from 'react'
import { AppController } from '../../appController/AppController'
import { MainWrapper } from '../../lib/wrapper'
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setProducAction, setSubMasteAction } from '../../redux/actions/allData';
import { useLocation } from 'react-router-dom'
import { Details } from './details';
import { ImagePreview } from './imagePreview';
import { ProductCard } from '../../components/cards/productCard'
import OwlCarousel from 'react-owl-carousel';
import { FileOutlined, HeartOutlined, MoreOutlined, RightOutlined, WhatsAppOutlined } from '@ant-design/icons'
import './style.css'
import { AddToFav } from '../../components/addToFav';
import _isEMpty from 'lodash/isEmpty'
import { RequestQuote } from '../../components/requestQuote';


export const SingleProduct = ({ history }) => {
	const [productData, setproductData] = useState(null);
	const [similarData, setSimilarData] = useState(null);
	const [moreImage, setMoreImage] = useState([]);
	const [subMasterID, setSubMasterID] = useState(null);
	const [subMasterdata, setsubMasterdata] = useState([])

	
	const allData = useSelector(state => state.allDataReducer)
	const dispatch = useDispatch();

	let location = useLocation();

	const res = {
		0: {
			items: 2,
			nav: false
		},
		680: {
			items: 4,
			nav: false,
		},
		1000: {
			items: 4,
			nav: true
		}
	}

	useEffect(() => {
		const productID = location.pathname.split('/')[2]
		const subMasterID = location.pathname.split('/')[3]
		if (allData.product.length) {
			let res = allData.product.filter(data => data.id === productID)[0] || null
			if (res) {
				// let subMasterID = res.parentID;
				setSubMasterID(subMasterID)
				let similarProduct = allData.product.filter(data => data.parentID === subMasterID) || []
				setSimilarData(similarProduct);
				setproductData(res)
			} else productData(null)
		} else dispatch(setProducAction())

		if (allData.subMaster.length) {
			let res = allData.subMaster
			setsubMasterdata(res)
		} else dispatch(setSubMasteAction())

	}, [allData.product, location, allData.subMaster])

	const GetSideMenu = () => {
		const [selectedColour, setColour] = useState(null)
	const [selectedSize, setSize] = useState(null)

		let colour = null;
		let size = null;
		let colourImage = null;
		if (productData.colour) {
			productData.colour = productData.colour.replace(/\\n/g, "\\n")
				.replace(/\\'/g, "\\'")
				.replace(/\\"/g, '\\"')
				.replace(/\\&/g, "\\&")
				.replace(/\\r/g, "\\r")
				.replace(/\\t/g, "\\t")
				.replace(/\\b/g, "\\b")
				.replace(/\\f/g, "\\f");

			productData.colour = productData.colour.replace(/[\u0000-\u0019]+/g, "");
			if (typeof productData.colour === 'string' && !_isEMpty(JSON.parse(productData.colour)))
				colour = JSON.parse(productData.colour);

		}
		if (productData.size) {

			productData.size = productData.size.replace(/\\n/g, "\\n")
				.replace(/\\'/g, "\\'")
				.replace(/\\"/g, '\\"')
				.replace(/\\&/g, "\\&")
				.replace(/\\r/g, "\\r")
				.replace(/\\t/g, "\\t")
				.replace(/\\b/g, "\\b")
				.replace(/\\f/g, "\\f")
			productData.size = productData.size.replace(/[\u0000-\u0019]+/g, "");
			
			// console.log(productData.size, typeof productData.size);

			// size = `'${productData.size}'`.replace(/\[|\]/g,'').split(',')
			
			if (typeof productData.size === 'string' && !_isEMpty(JSON.parse(productData.size)))
				size = JSON.parse(productData.size);
		}

		if (productData.colourImage) {
			productData.colourImage = productData.colourImage.replace(/\\n/g, "\\n")
				.replace(/\\'/g, "\\'")
				.replace(/\\"/g, '\\"')
				.replace(/\\&/g, "\\&")
				.replace(/\\r/g, "\\r")
				.replace(/\\t/g, "\\t")
				.replace(/\\b/g, "\\b")
				.replace(/\\f/g, "\\f");

			productData.colourImage = productData.colourImage.replace(/[\u0000-\u0019]+/g, "");
			if (!_isEMpty(JSON.parse(productData.colourImage)))
				colourImage = JSON.parse(productData.colourImage);
		}
		// console.log(productData)

		const handleImageChange = (colour) => {
			setColour(colour)
			if (colourImage) {
				if (colourImage[colour]) {
					setColour(colour)
					setMoreImage(colourImage[colour])
				}
			}
		}

		return (
			<div style={{ width: '100%' }} className="sticky">
				<h1 className="cs-product-title">{productData.name}</h1>
				{productData && <div className="mt-30 mb-20 cs-product-sub-title" dangerouslySetInnerHTML={{ __html: productData.description }} />}
				{productData && <div className="mt-10 mb-10">SKU: {productData.sku}</div>}
				{colour && <Select onChange={(val) => handleImageChange(val)} style={{ width: '100%' }} placeholder="please select colour" >
					{colour.map(data => <Select.Option value={data.colour} >{data.colour}</Select.Option>)}
				</Select>}
				{size && <Select onChange={(val) => { console.log(val); setSize(val)}} value={selectedSize} style={{ width: '100%' }} className="mt-10 " placeholder="please select size" >
					{size.map(data => <Select.Option value={data.size} >{data.size}</Select.Option>)}
				</Select>}
				{productData && <RequestQuote selectedColour={selectedColour} selectedSize={selectedSize} productData={productData} his={history} />}
				{productData && <AddToFav productData={productData} history={history} />}
				{productData && <div style={{ display: 'flex', textAlign: 'center' }} >
					<a
						href={`https://wa.me/917665529259?text=Hi, I have query regarding product sku: ${productData.sku}`}
						class="btn-wp-green"
						target="_blank"
						rel="noopener noreferrer"
					>
						<WhatsAppOutlined style={{ marginRight: 10 }} />
						Whatsapp
					</a>
				</div>}
			</div>
		)
	}

	const getBread = () => {
		let mapData = [];
		if (subMasterID && allData.productMapping && allData.productMapping.length) {
			for (let parent of allData.productMapping) {
				for (let master of parent.children) {
					for (let subMaster of master.children) {
						if (subMaster.id == subMasterID) {
							mapData = [parent.name, master.name, subMaster.name, productData.name]
						}
					}
				}
			}
		}
		return (
			<div className="d mt-30 mb-10" style={{flexWrap:"nowrap", overflow:"auto"}} >
				{mapData.map((data, idx) => <div className="d" >
					{idx !== 0 && <div style={{margin:'0 5px'}} > <RightOutlined style={{fontSize:12}} /> </div>}
					{idx !== 2 ? <div style={{whiteSpace:"pre"}}  >{data}</div> : <div style={{cursor:"pointer", whiteSpace:"pre"}}  onClick={()=>history.push(`/category/${subMasterID}/${subMasterdata.filter(data => data.id === similarData[0].parentID)[0]['name'].split(' ').join('-')}`)} >{data}</div>}
				</div>)}
			</div>
		)
	}

	const getMoreProduct = () => {
		return (
			similarData ? (<div style={{ width: '100%' }} className="more-product">
				<h2 className="mb-20">More Related Product</h2>
				<Row gutter={[16, 16]}>
					{similarData !== null && similarData.map((productData, idx) => idx < 4 &&
						<Col md={12} xl={12}>
							<ProductCard history={history} noTitle={true} data={productData} noAction={false} />
						</Col>
					)}
					<Col span={24}>
						<Button onClick={() => history.push(`/category/${similarData[0].parentID}/${subMasterdata.filter(data => data.id === similarData[0].parentID)[0]['name'].split(' ').join('-')}`)} style={{ width: "100%" }} type="dashed"> View More</Button>
					</Col>
				</Row>
			</div>) : null
		)
	}
	return (
		<div>
			<AppController history={history}>
				<MainWrapper>
					<div className="mt-30">
						{getBread()}
						<Row gutter={[16, 16]}>
							<Col xs={24} sm={24} md={24} lg={12}>
								{productData ? <ImagePreview moreImage={moreImage} productData={productData} /> : <Skeleton active />}
								<div className="cs-only-for-mobile d jc-fs mt-30" >
									{productData ? <GetSideMenu /> : <Skeleton active className="mt-30" />}
								</div>
							</Col>
							<Col xs={24} sm={24} md={24} lg={6}>
								<div className="cs-not-for-mobile p-1">
									{productData ? <GetSideMenu /> : <Skeleton active className="mt-30" />}
								</div>
							</Col>
							<Col xs={24} sm={24} md={24} lg={6}>
								<div className="cs-not-for-mobile">
									{similarData !== null && getMoreProduct(history)}
								</div>
							</Col>
							<Col xs={24} sm={24} md={24} lg={18}>
								{productData ? <Details data={productData} /> : <Skeleton active className="mt-30" />}
							</Col>
						</Row>
						{similarData && <div className="display-column mobile-mt">
							<div className="d jc-sb ai-center ">
								<div className="detail-title">
									similiar products
								</div>
								<div className="cur" onClick={() => history.push(`/category/${productData.parentID}/${subMasterdata.filter(data => data.id === productData.parentID)[0]['name'].split(' ').join('-')}`)}>
									View all
								</div>
							</div>
							<div className="">
								<OwlCarousel className='owl-theme' responsive={res} margin={10} responsiveClass={true}  >
									{similarData !== null && similarData.filter((_, id) => id > 3).map(productData =>
										<ProductCard history={history} data={productData} noAction={false} />
									)}
								</OwlCarousel>
							</div>
						</div>}

					</div>
				</MainWrapper>
			</AppController>
		</div>
	)
}
