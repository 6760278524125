import { BulbOutlined, FileOutlined, SearchOutlined } from "@ant-design/icons";
import { Col, Row, Button, Input, Popover, Menu } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { mangeLogin } from "../../redux/actions/appStatus";
import "./style.css";
import logo from '../../assets/images/whiteLogo.png'

const { SubMenu } = Menu;

export const WebHeader = ({ history }) => {
	const [data, setData] = useState([])
	const [popoverVisible, setPopoverVisible] = useState(false)
	const [searchIn, setSearchIn] = useState('All Category')
	const appStatus = useSelector(state => state.appStatus)
	const allData = useSelector(state => state.allDataReducer)
	const [subProductKey, setSubProductKey] = useState(0)
	const [openKeys, setOpenKeys] = useState([])

	const dispatch = useDispatch()

	useEffect(() => {
		if (allData.productMapping) {
			setData(allData.productMapping)
		}
	}, [allData.productMapping])

	const inspireContent = (
		<div className="header-item-drop" >
			<ul style={{ listStyle: 'none', textAlign: 'left', padding: 0 }}>
				<li onClick={() => history.push('/blog')} className="list-item arc-sub-title">Blog</li>
			</ul>
		</div>
	);

	const manageContent = (
		<div className="header-item-drop" >
			<ul style={{ listStyle: 'none', textAlign: 'left', padding: 0 }}>
				<li onClick={() => history.push('/quote')} className="list-item arc-sub-title">Quote</li>
				<li onClick={() => history.push('/order')} className="list-item arc-sub-title">Order</li>
				<li onClick={() => history.push('/favourite')} className="list-item arc-sub-title">Favorite</li>
			</ul>
		</div>
	);

	const getFormobile = (data) => {

		const handleClick = ({ key }) => {
			setSearchIn(key.split('__')[0]);
			setSubProductKey(key.split('__')[1]);
			setOpenKeys([])
			setPopoverVisible(false)
		};

		return (
			<Menu
				className="header-menu"
				// className="cs-only-for-mobile"
				onClick={handleClick}
				openKeys={openKeys}
				onOpenChange={(key) => setOpenKeys(key)}
				style={{ height: 38, backgroundColor: '#f2f3f4', position: 'relative', margin: 0 }}
				defaultSelectedKeys={['sub1']}
				theme="light"
				mode="inline"
			>
				<SubMenu style={{ width: '100%' }} key={"All"} title={searchIn}>

					{
						data.map(parentData =>
							<SubMenu style={{ backgroundColor: '#fff', width: '100%' }} key={parentData.id} title={parentData.name}>
								{
									parentData.children.map(masterData =>
										<SubMenu style={{ backgroundColor: '#fff', width: '100%' }} key={masterData.id} title={masterData.name}>
											{masterData.children.map(subMasterData =>
												<Menu.Item style={{ backgroundColor: '#fff', margin: 0 }} key={`${subMasterData.name}__${subMasterData.id}`}>{subMasterData.name}</Menu.Item>
											)}
										</SubMenu>
									)
								}
							</SubMenu>
						)
					}
				</SubMenu>
			</Menu>
		)
	}

	const handleSearch = val => {
		if(val)
			history.push(`/search/${subProductKey}/${val}`)
	}

	const logout = () => {
		localStorage.setItem("d59517ae-e16e-11eb-ba80-0242ac130004", false);
		localStorage.setItem("id", null);
		localStorage.setItem("username", null);
		dispatch(mangeLogin(false, null, null))

	}

	return (
		<div className="cs-not-for-mobile header-content d jc-c" >
			<div style={{ maxWidth: '1442px', width: '100%' }}>
				<Row gutter={16} style={{ width: "100%", height: 80 }} justify="center">
					<Col span={3}>
						<img className="ai-center mt-15 cur" onClick={() => history.push('/')}
						 style={{ width: '90%' }} src={logo} alt="logo" />
						{/* <h1 onClick={() => history.push('/')} className="ai-center mt-25 txt-logo">Furniture Boutiq</h1> */}
					</Col>
					<Col span={4}>
						<Row style={{ width: "100%", height: 80 }}  >
							<Col span={12}>
								<Popover content={inspireContent} placement="bottomLeft">
									<div className="d-flex ai-center jc-c header-item header-item-first" style={{ textAlign: 'center', height: '100%' }}>
										<BulbOutlined />
										<p className="header-text arc-sub-title arc-sub-title">Inspire</p>
									</div>
								</Popover>
							</Col>
							<Col span={12}>
								<Popover content={manageContent} placement="bottomLeft">
									<div className="d-flex ai-center jc-c header-item header-item-first" style={{ textAlign: 'center', height: '100%' }}>
										<FileOutlined />
										<p className="header-text arc-sub-title">Manage</p>
									</div>
								</Popover>
							</Col>
						</Row>
					</Col>
					<Col span={12} >
						<div className="d-flex jc-c ai-center mt-20 ">
							<Input.Search style={{ width: '70%' }} className="center-button"
								onSearch={handleSearch} size="large" placeholder="Search by products, Category, SKU" allowClear={true} prefix={<SearchOutlined />} />
							<div style={{ zIndex: 99999 }}  >{getFormobile(data)}</div>
						</div>
					</Col>
					{appStatus.login ? null : <Col span={2}>
						<div className="mt-15 ">
							<Button onClick={() => { history.push('/log-in'); }} className="outline-button">LOG IN</Button>
						</div>
					</Col>}
					{appStatus.login ? null : <Col span={2}>
						<div className="mt-15">
							<Button onClick={() => history.push('/sign-up')} className="white-button">SIGN UP</Button>
						</div>
					</Col>}
					{appStatus.login && <Col span={2}>
						<div className="mt-15">
							<Button onClick={() => history.push('/account')} className="white-button">Profile</Button>
						</div>
					</Col>}
					{appStatus.login && <Col span={2}>
						<div className="mt-15">
							<Button onClick={() => logout()} className="outline-button">Logout</Button>
						</div>
					</Col>}
				</Row>
			</div>
		</div>
	);
};
