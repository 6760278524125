import React from "react";
import { Row, Col, Divider, Collapse, Button } from "antd";
import "./style.css";
import { AppController } from "../../appController/AppController";
import { MainWrapper } from "../../lib/wrapper";
import { tradeImageURL } from "../../lib/constant";

export const ToTheTrade = ({ history }) => {
	const getHeader = (value) => {
		return (
			<div className="mt-10 mb-10">
				<div className="detail-title">{value}</div>
			</div>
		)
	}


	return (
		<div>
			<AppController history={history}>
				<MainWrapper>
					<Row className="cs-not-for-mobile">
						<Col md={24} xl={24}>
							<div className="panel-overlay h-100">
								<div className="container h-100">
									<Row justify="start" align="bottom">
										<Col md={6} xl={6}>
											<div className="panel-card">
												<h1>Designer Trade Program</h1>
											</div>
										</Col>
									</Row>
								</div>
							</div>
							<img
								style={{ width: "100%" }}
								alt="insider program"
								src={tradeImageURL + "1.png"}
							/>
						</Col>
					</Row>
					<div className="cs-only-for-mobile">
						<h1>Designer Trade Program</h1>
					</div>
					<div className="mobile-mt">
						<div>DEVELOPED BY DESIGNERS FOR DESIGNERS</div>
						<Divider />
					</div>
					<Row gutter={[16, 16]} justify="center" align="top">
						<Col sm={24} xs={23} md={12} xl={12}>
							<div className="d jc-sa">
								<img
									style={{ height: "90%", width: "50%" }}
									src={tradeImageURL + "2.png"}
									alt="Our Designer Trade Program was developed by designers for designers."
								/>
							</div>
						</Col>
						<Col sm={24} xs={23} md={12} xl={12} className="p-1">
							Created by designers for designers , our Trade Program makes the shopping experience as smooth as
							possible by offering low prices, quick access to a service representative, and simplified invoicing
							through in-store and online tax exemptions. You can trust Furniture BoutiQ to be your one-stop shop
							for high-quality furniture as we collaborate with artists and manufacturers on a regular basis to
							provide you with one-of-a-kind products for your projects.
							Interior designers aren't the only ones that benefit from our Designer Trade Program! Architects,
							home developers, set designers, home stagers, and hospitality and food beverage industry experts
							are all welcome to create a custom experience for their clients.
							<br />
							As a Designer Trade member, you will receive the following benefits: Receive an exclusive discount
							on ALL products. A tiered discount scheme is available to our Designer Trade members. The more
							you spend at Furniture BoutiQ, the more money you'll save! We offer unique discounts to interior
							decorators, designers, and architects on all full-price products with no minimum purchase. You can
							be confident that with our vast assortment of items and styles, you will be able to meet the needs of
							your clients. Our in-store trade professionals will ensure that the process is straightforward, smooth,
							and successful for you and your clients from beginning to end. You can trust our collaboration and
							assistance with any size of project, from purchasing through tracking, delivery, customer
							satisfaction, and warranty.
						</Col>
					</Row>
				</MainWrapper>
				<div className="mobile-mt " style={{ backgroundColor: "#f5f5f5" }}>
					<MainWrapper>
						<Row gutter={[0, 16]} align="middle" style={{textAlign:'start'}}>
							<Col md={10} sm={24} xl={8} className="step-panel">
								<div className="display-column">
									<h1 className="app-title">
										HOW DO I
										<br />REGISTER?
									</h1>
									<div>
										Join the Designer Trade Program in 3 easy steps.								</div>
								</div>
							</Col>
							<div
								className="cs-not-for-mobile vr-line"
								style={{ height: 500 }}
							/>


							<Col md={4} sm={24} xl={4} className="step-panel">
								<div className="mb-20">STEP 01</div>
								<h2 className="mb-30">
									JOIN US <br /> TODAY!
								</h2>
								<div>
									Fill out this easy online form. Keep your resale or business licenses handy!
								</div>
							</Col>
							<div
								className="cs-not-for-mobile vr-line"
								style={{ height: 500 }}
							/>
							<div className="cs-only-for-mobile hr-line" />

							<Col md={4} sm={24} xl={4} className="step-panel">
								<div className="mb-20">STEP 02</div>
								<h2 className="mb-30">
									GET <br /> APPROVED
								</h2>
								<div>
									We'll send you a confirmation email with a special discount code for any future purchases once your
									application has been submitted and processed on our end.
								</div>
							</Col>
							<div
								className="cs-not-for-mobile vr-line"
								style={{ height: 500 }}
							/>
							<div className="cs-only-for-mobile hr-line" />

							<Col md={4} sm={24} xl={4} className="step-panel">
								<div className="mb-20">STEP 03</div>
								<h2 className="mb-30">
									PLACE YOUR
									<br /> ORDER
								</h2>
								<div>
									To maximize your savings, use your unique discount code at checkout!
								</div>
							</Col>
						</Row>
					</MainWrapper>
				</div>
				<MainWrapper>
					<Row justify="center" className="mobile-mt">
						<Col sm={20} md={12} xl={12} style={{ textAlign: "center" }}>
							<h1 className="app-title">
								How The Trade Program Works
							</h1>
							<div>
								Your level status is determined by how much you spend at Furniture BoutiQ each calendar year
								(after special offers and shipping charges are included) when you input your discount code atcheckout that is registered to your Trade account. Please note there is 30-day delay in the spend
								calculation that places you in your tier status.
							</div>
						</Col>
					</Row>
				</MainWrapper>
				<div className="mobile-mt " style={{ backgroundColor: "#f5f5f5" }}>
					<MainWrapper>
						<Row gutter={[16, 16]} justify="center">
							<Col sm={24} md={6} xl={6}>
								<div class="panel-card h-100">
									<h3 class="f2-sm">Level 01</h3>
									<div class="bg-tan full-label">
										<h5>Spend $10,000 - $15,000</h5>
									</div>
									<ul class="list bullets">
										<li>10% off your purchase</li>
										<li>Free decorative products</li>
									</ul>
								</div>
							</Col>
							<Col sm={24} md={6} xl={6}>
								<div class="panel-card h-100">
									<h3 class="f2-sm">Level 02</h3>
									<div class="bg-tan full-label">
										<h5>$15,000-$30,000</h5>
									</div>
									<ul class="list bullets">
										<li>15% Off Any Purchase</li>
										{/* <li>Free Shipping/ Returns (On Decor, Lighting, And Rugs)</li> */}
										<li>Free decorative products</li>
									</ul>
								</div>
							</Col>
							<Col sm={24} md={6} xl={6}>
								<div class="panel-card h-100">
									<h3 class="f2-sm">Level 03</h3>
									<div class="bg-tan full-label">
										<h5>Over $30,000</h5>
									</div>
									<ul class="list bullets">
										<li>15% Off Any Purchase</li>
										<li>Free decorative products</li>
										<li>Free FOB shipping</li>
										{/* <li>Free Returns On Rug Swatches</li> */}
										{/* <li>Free SM Textile Swatches</li> */}
									</ul>
								</div>
							</Col>
						</Row>
					</MainWrapper>
				</div>
				<div className="cs-only-for-mobile mobile-mt">
					<Row>
						<Col sm={24} md={24} xl={12}>
							<img
								style={{ width: "100%" }}
								alt="Inside The Program"
								src={tradeImageURL + "3.png"}
							/>
						</Col>
						<MainWrapper>
							<Col sm={24} md={24} xl={12} className="mt-20" >
								<h3>Inside The Program</h3>
								<div>
									Finding the perfect furnishings for each space was easy when we first started in interior design, but
									managing the logistics was difficult! We intended to build a Trade programme that made the
									purchase process smooth and simple. Our Trade programme provides members with unrestricted
									access to our finest items and materials at a reduced price. We aim to make this procedure as simple
									as possible for you so that you can give your clients the finest service possible!
								</div>
							</Col>
						</MainWrapper>
					</Row>
				</div>
				<Row className="cs-not-for-mobile mobile-mt">
					<Col md={24} xl={24}>
						<div className="panel-overlay h-100">
							<div className="container h-100">
								<Row justify="end" align="bottom">
									<Col md={8} xl={8}>
										<div className="panel-card" style={{ paddingBottom: 150, paddingLeft: 50, paddingRight: 50 }}>
											<h1>Inside The Program</h1>
											<div>
												Finding the perfect furnishings for each space was easy when we first started in interior design, but
												managing the logistics was difficult! We intended to build a Trade programme that made the
												purchase process smooth and simple. Our Trade programme provides members with unrestricted
												access to our finest items and materials at a reduced price. We aim to make this procedure as simple
												as possible for you so that you can give your clients the finest service possible!
											</div>
										</div>
									</Col>
								</Row>
							</div>
						</div>
						<img
							style={{ width: "100%" }}
							alt="insider program"
							src={tradeImageURL + "3.png"}
						/>
					</Col>
				</Row>
				<MainWrapper>
					<Button onClick={() => history.push('/member')} type="primary">
						Sign Up Today
					</Button>
				</MainWrapper>
				{/* <MainWrapper>
					<Row gutter={[16, 16]} justify="space-around" align="stretch" className="mobile-mt" >
						<Col sm={24} md={24} xl={24}>
							<h2 className="app-title" >Frequently Asked Questions</h2>
						</Col>
						<Col sm={24} xs={24} md={24} xl={24}>
							<Collapse ghost expandIconPosition="right" style={{ width: '100%' }} >
								<Collapse.Panel className="produc-des-collapse" header={getHeader("Description")} >
									Hellow
								</Collapse.Panel>
								<Collapse.Panel className="produc-des-collapse" header={getHeader("Description")} >
									Hellow
								</Collapse.Panel>
								<Collapse.Panel className="produc-des-collapse" header={getHeader("Description")} >
									Hellow
								</Collapse.Panel>
								<Collapse.Panel className="produc-des-collapse" header={getHeader("Description")} >
									Hellow
								</Collapse.Panel>
							</Collapse>
						</Col>
					</Row>
				</MainWrapper> */}
			</AppController>
		</div>
	);
};
